import { useCdnData } from '@store';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const useBreadcrumbItems = (
  pathname: string,
  cdnData: ReturnType<typeof useCdnData>,
  translate: (key: string) => string,
) => {
  const idMap = useMemo(
    () => cdnData.length > 0 && cdnData.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}),
    [cdnData],
  );
  const navigate = useNavigate();
  const breadcrumbItems = useMemo(() => {
    return pathname
      .split('/')
      .filter(Boolean)
      .map((segment) => {
        const translatedName = translate(`navigator.${segment}`);
        const name = idMap[segment] ?? translatedName ?? segment;

        return {
          id: idMap[segment] ? `${segment}/configuration` : segment,
          name: name,
        };
      })
      .filter((item) => item.id !== 'configuration');
  }, [pathname, idMap, translate]);

  useEffect(() => {
    const hasUnresolved = breadcrumbItems.some((item) => item.name.startsWith('navigator.'));
    if (hasUnresolved && cdnData.length > 0) {
      const getFirstPathnameSegment = () => {
        const pathSegments = pathname.split('/').filter(Boolean);
        return pathSegments[0];
      };
      navigate(`/${getFirstPathnameSegment()}`);
    }
  }, [breadcrumbItems, cdnData, navigate, pathname]);

  return breadcrumbItems;
};
