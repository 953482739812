import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { workSpaceId } from '@/storage';
import { Button, Cards, DraggableTable, Text } from '@components';
import { getDashboardAttackLogsColumns } from '@constants/components/cdnDashboardTable';
import { CdnConfigurationDashboardGraph, CdnConfigurationTab, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getDashboardData,
  updateAutoReload,
  useConfigurationAutoReload,
  useConfigurationDashboardData,
  useConfigurationDashboardLastUpdated,
} from '@store';
import { formatUnixTimestamp, getGraphTypeValue } from '@utils';

export const AttackLog = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();
  const workspace_id = workSpaceId.getItem();

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.dashboard' });

  const attackLogTableColumns = useMemo(getDashboardAttackLogsColumns, []);

  const dashboardData = useConfigurationDashboardData();
  const attackLoggedData = getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.AttackLog);
  const dashboardAutoReload = useConfigurationAutoReload(CdnConfigurationTab.Dashboard) ?? true;
  const lastUpdated = useConfigurationDashboardLastUpdated({ tableKey: CdnConfigurationTab.Dashboard });
  const formattedLastUpdated = formatUnixTimestamp(lastUpdated);

  const handleAutoReload = useCallback(() => {
    const newAutoReloadStatus = !dashboardAutoReload;
    dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: newAutoReloadStatus }));
    if (newAutoReloadStatus) {
      dispatch(
        getDashboardData({
          graphTypeKey: CdnConfigurationDashboardGraph.AttackLog,
          application_id,
        }),
      );
    }
  }, [dashboardAutoReload, dispatch, application_id, workspace_id]);

  useEffect(() => {
    dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: true, clear: false }));
    dispatch(
      getDashboardData({
        tableKey: CdnConfigurationTab.Dashboard,
        graphTypeKey: CdnConfigurationDashboardGraph.AttackLog,
        application_id,
      }),
    );

    return () => {
      dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: false, clear: true }));
    };
  }, []);

  return (
    <Cards rounded="rounded-2xl px-8 py-7 space-y-6" withoutPadding>
      <div className="flex justify-between">
        <article className="font-medium text-theme-primary-main">{tTabs('attackLogs')}</article>
        <div className="flex gap-5 items-center">
          <Text className="" color="text-gray-500" $level={6}>
            {tTabs('lastUpdated', { date: formattedLastUpdated })}
          </Text>
          <Button
            onClick={handleAutoReload}
            variant={VariantType.Icon}
            icon={
              <ArrowPathIcon
                className={`w-4 h-4 ${dashboardAutoReload ? 'animate-spin-gradual text-theme-primary-main' : 'text-zinc-300'}`}
              />
            }
          />
        </div>
      </div>
      <DraggableTable
        rows={attackLoggedData}
        columns={attackLogTableColumns}
        isDataLoading={false}
        tableKey="dashboard"
        actionHandlers={{}}
        onChangePage={() => console.log('hi')}
      />
    </Cards>
  );
};
