import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useField } from 'formik';
import { Fragment } from 'react';
import { Button } from '../Buttons';

import { WAFRulesType } from '@enums';
import { convertEnumToObjectArray } from '@utils';

export const MultipleSelectCard = ({ name }) => {
  const [field, , helpers] = useField(name);

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    const selectedIndex = field.value.findIndex((item) => item.value === option.value);
    const newSelected = [...field.value];

    if (selectedIndex === -1) {
      newSelected.push(option);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    helpers.setValue(newSelected);
  };

  const handleDeleteClick = (index) => {
    const newValues = [...field.value];
    newValues.splice(index, 1);
    helpers.setValue(newValues);
  };

  return (
    <Listbox value={field.value}>
      <div className="relative z-30">
        <ListboxButton
          className={`flex w-full cursor-default ring-1 ring-inset  ring-theme-text-secondary rounded-md bg-white pl-3 pr-3 py-2.5 space-x-5 text-left shadow-sm focus:outline-none text-black sm:text-sm hover:cursor-pointer `}
        >
          <div className="flex flex-1 flex-wrap -m-2.5 pb-2.5 pl-2.5">
            {field?.value.map((item, index) => (
              <div className="mt-2.5 mr-2.5" key={index}>
                <Button
                  withoutPadding
                  onClick={() => handleDeleteClick(index)}
                  className="max-w-30 z-40 flex items-center ring-1 text-indigo-700 px-2 py-1 bg-blue-50 rounded-full"
                >
                  <article className="text-us truncate">{item.label ?? item.value}</article>
                  <div className="ml-2 rounded-full ">
                    <XMarkIcon className="w-3 h-3" />
                  </div>
                </Button>
              </div>
            ))}
          </div>
          {/* Display selected options */}
          <span className="flex items-center">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <ListboxOptions
            className={`absolute mt-1 max-h-60 min-w-40 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm `}
          >
            {[...convertEnumToObjectArray(WAFRulesType)].map((option, optionIdx) => (
              <ListboxOption key={optionIdx} className={() => `relative cursor-default select-none `} value={option}>
                {() => (
                  <Button
                    icon={
                      field.value.some((item) => item.value === option.value) && (
                        <CheckIcon className="h-3 w-3" aria-hidden="true" />
                      )
                    }
                    rounded="rounded-none"
                    onClick={(e) => handleOptionClick(e, option)}
                    className={`block truncate px-2.5 w-full ${
                      field.value.some((item) => item.value === option.value)
                        ? 'font-medium bg-green-100 text-green-500'
                        : 'font-normal'
                    }`}
                  >
                    {option.label}
                  </Button>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
};
