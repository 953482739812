import { Formik } from 'formik';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SelectBox, Skeleton } from '@components';
import { useAppDispatch } from '@hooks';
import { resetConfiguration, selectCurrentCdnData, useCdnData, useCdnLoading } from '@store';

export const ApplicationSelectBox = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isCdnLoading = useCdnLoading();
  const cdnData = useCdnData();

  const applicationOptionList = useMemo(
    () =>
      cdnData.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [cdnData],
  );

  const changeApplication = ({ value }) => {
    if (id) {
      const newPath = location.pathname.replace(id, value?.toString());
      if (newPath !== location.pathname) {
        navigate(newPath);
        const matchingCdnData = cdnData.find((cdnData) => cdnData.id === value);
        if (matchingCdnData) {
          dispatch(selectCurrentCdnData({ rowData: matchingCdnData }));
        }
        dispatch(resetConfiguration());
      }
    }
  };

  return (
    <Formik
      initialValues={{
        application_id: id,
      }}
      onSubmit={() => {}}
    >
      {() => {
        if (isCdnLoading) return <Skeleton className="w-5 h-6" />;
        return (
          <SelectBox
            name="application_id"
            smallFont
            menuItemClassName="px-3 py-5"
            options={applicationOptionList}
            onOptionSelect={changeApplication}
          />
        );
      }}
    </Formik>
  );
};
