import { Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { applicationFormSchema } from '@/constants';
import { useAppDispatch } from '@/hooks';
import {
  resetCdnForm,
  resetConfiguration,
  submitCdnApplicationForm,
  useCdnData,
  useCdnError,
  useCdnSaveSuccess,
  useCdnSubmitting,
  useCdnTableData,
} from '@/store';
import { FormField, FormModal } from '@components';
import { columnDirection, InputType, originType } from '@enums';
import { Dictionaries, getOptionListFromCatalog, validateValueEqual } from '@utils';

interface ApplciationFormProps {
  open: boolean;
  onClose: () => void;
}

export const CdnApplicationModal = ({ open, onClose }: ApplciationFormProps) => {
  const { t: tCdnApplicationForm } = useTranslation('form', { keyPrefix: 'addApplication.content' });
  const { t: tModal } = useTranslation('cdn', { keyPrefix: 'modals' });
  const { t: tValidation } = useTranslation('validation', { keyPrefix: 'format' });
  const { t: tCommonIp } = useTranslation('common', { keyPrefix: 'ip' });
  const { t: tOriginForm } = useTranslation('configuration', { keyPrefix: 'forms.origin' });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const submitError = useCdnError();
  const isSubmitting = useCdnSubmitting();
  const saveSuccess = useCdnSaveSuccess();
  const currentTableData = useCdnTableData();
  const cdnData = useCdnData();

  const handleSubmit = (values: any) => {
    const updatedValues = {
      ...values,
      verify_ssl_cert: values.type === originType.HTTPS ? values.verify_ssl_cert : null,
    };
    dispatch(submitCdnApplicationForm(updatedValues));
  };

  const cdnOptions = cdnData?.map((option) => ({
    value: option.name,
    label: option.name,
  }));

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetCdnForm());
      dispatch(resetConfiguration());
      currentTableData?.id && navigate(`${currentTableData.id}/configuration`);
    }
  }, [currentTableData, dispatch, isSubmitting, navigate, onClose, saveSuccess, submitError]);

  const initialValues: any = {
    name: '',
    domain: '',
    type: originType.HTTPS,
    origin: '',
    verify_ssl_cert: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={applicationFormSchema({ optionList: cdnOptions })}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors }) => {
        return (
          <FormModal
            title={tModal('addNewApplication')}
            description={tModal('addNewApplicationDescription')}
            onClose={onClose}
            submitError={submitError}
            open={open}
            size="lg"
            confirmButton={{ children: tModal('addNewApplication') }}
          >
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('applicationName')}
              name="name"
              smallFont
              placeholder={tCdnApplicationForm('enterYourApplicationName')}
              isCorrect={touched.name && !errors.name}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('domainName')}
              name="domain"
              hints={tValidation('domain')}
              smallFont
              placeholder={tCdnApplicationForm('enterYourDomain')}
              required
              fullWidth
            />
            <FormField
              direction={columnDirection.Row}
              label={tCdnApplicationForm('originType')}
              className={'text-gray-400'}
              name="type"
              smallFont
              inputType={InputType.Select}
              fullWidth
              placeholder={tCdnApplicationForm('selectYourOriginType')}
              options={getOptionListFromCatalog(Dictionaries.OriginType)}
            />
            <FormField
              direction={columnDirection.Row}
              label={
                <article className="text-gray-700 text-left text-sm font-normal leading-6">
                  {`${tCdnApplicationForm('origin')}`}
                  <span className="text-gray-400 text-us"> {`(${tCommonIp('ipv4Address')})`}</span>
                </article>
              }
              smallFont
              hints={tValidation('ip')}
              name="origin"
              placeholder={tCdnApplicationForm('enterYourOrigin')}
              fullWidth
            />
            {validateValueEqual(values.type, originType.HTTPS) && (
              <FormField
                label={tOriginForm('verifySSL')}
                name="verify_ssl_cert"
                inputType={InputType.Switch}
                direction={columnDirection.Row}
                smallFont
                mobileTextInput="justify-between"
              />
            )}
          </FormModal>
        );
      }}
    </Formik>
  );
};
