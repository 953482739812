import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '@hooks';

export const cdnInitialState: any = {
  loading: false,
  isSubmitting: false,
  saveSuccess: false,
  loadSuccess: false,
  list: [],
  content: {},
  error: null,
};

export const cdnSlice = createSlice({
  name: 'cdn',
  initialState: cdnInitialState,
  reducers: {
    getCdnList: (state) => {
      state.loading = true;
      state.loadSuccess = false;
    },
    getCdnListSuccess(state, { payload }: PayloadAction<any>) {
      state.loading = false;
      state.list = payload;
      state.loadSuccess = true;
    },
    getCdnListError(state, { payload }: PayloadAction<any>) {
      state.error = payload;
      state.loading = false;
      state.loadSuccess = false;
    },
    resetCdnForm: (state) => {
      state.isSubmitting = cdnInitialState.isSubmitting;
      state.saveSuccess = cdnInitialState.saveSuccess;
      state.loadSuccess = cdnInitialState.loadSuccess;
    },
    clearCdnError: (state) => {
      state.error = null;
    },
    submitCdnApplicationForm: (state) => {
      state.isSubmitting = true;
      state.saveSuccess = false;
    },
    createCdnApplicationSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isSubmitting = false;
      state.saveSuccess = true;
      state.content = payload;
    },
    createCdnApplicationError: (state, { payload }: PayloadAction<any>) => {
      state.isSubmitting = false;
      state.saveSuccess = false;
      state.error = payload;
    },
    selectCurrentCdnData(state, { payload }: PayloadAction<{ rowData: any }>) {
      state.content = payload.rowData;
    },
  },
});

export const {
  getCdnList,
  getCdnListSuccess,
  getCdnListError,
  selectCurrentCdnData,
  submitCdnApplicationForm,
  createCdnApplicationSuccess,
  createCdnApplicationError,
  clearCdnError,
  resetCdnForm,
} = cdnSlice.actions;

export const useCdnLoading = () => useAppSelector((state: RootState) => state.cdn.loading);
export const useCdnSubmitting = () => useAppSelector((state: RootState) => state.cdn.isSubmitting);
export const useCdnSaveSuccess = () => useAppSelector((state: RootState) => state.cdn.saveSuccess);
export const useCdnLoadSuccess = () => useAppSelector((state: RootState) => state.cdn.loadSuccess);
export const useCdnData = () => useAppSelector((state: RootState) => state.cdn.list);
export const useCdnTableData = () => useAppSelector((state: RootState) => state.cdn.content);
export const useCdnError = () => useAppSelector((state: RootState) => state.cdn.error);

const cdnReducer = cdnSlice.reducer;
export default cdnReducer;
