import React, { memo, useMemo } from 'react';

import { Text } from '../Text';
import { Cards } from './Cards';

interface PredefinedCardProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  besideTitle?: boolean;
}

const TitleComponent = memo(({ title }: { title: React.ReactNode }) =>
  typeof title === 'string' ? (
    <Text $level={4} color="text-black" className="font-medium leading-5">
      {title}
    </Text>
  ) : (
    title
  ),
);

const DescriptionComponent = memo(({ description }: { description?: React.ReactNode }) =>
  typeof description === 'string' ? (
    <Text $level={5} color="text-gray-400" className="font-light text-justify leading-4">
      {description}
    </Text>
  ) : (
    description
  ),
);

export const PredefinedCards: React.FC<PredefinedCardProps> = memo(
  ({ title, description, children, besideTitle = false }) => {
    const contentClasses = useMemo(() => 'flex flex-col sm:flex-row justify-between overflow-hidden', []);

    const leftColumnClasses = useMemo(() => 'flex flex-col gap-2 p-6 sm:p-8 sm:flex-1', []);
    const rightColumnClasses = useMemo(
      () =>
        'sm:shadow-card flex sm:border-l flex-col gap-4 w-full sm:max-w-64 px-6 pb-6 sm:p-6 justify-center items-center sm:bg-gray-400 sm:bg-opacity-5 rounded-b-2xl sm:rounded-r-2xl sm:rounded-bl-none',
      [],
    );

    return (
      <Cards rounded="rounded-2xl" withoutPadding className={contentClasses}>
        <div className={leftColumnClasses}>
          <div className="flex gap-3 justify-between">
            <TitleComponent title={title} />
            {besideTitle && <div className="pb-1">{children}</div>}
          </div>
          <DescriptionComponent description={description} />
        </div>
        {!besideTitle && <div className={rightColumnClasses}>{children}</div>}
      </Cards>
    );
  },
);
