import { call, put, take, takeLatest } from 'redux-saga/effects';

import { initData, updateDataInStorage } from '@/storage';
import { ViewPermissions } from '@enums';
import {
  appInitError,
  appInitSuccess,
  getUserSummary,
  getUserSummarySuccess,
  getWorkSpaceListSuccess,
  getWorkspaceList,
  setAppLoading,
  updateToken,
} from '@store';
import { API } from '@utils';

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
}

function* getUserSummarySaga() {
  try {
    yield put(setAppLoading(true));
    const { data: userData } = yield call(API.get, '/user/info');

    yield put(getWorkspaceList());
    yield take(getWorkSpaceListSuccess);

    if (userData) {
      yield call(
        updateDataInStorage,
        initData,
        ['userEmail', 'userId', 'firstName', 'lastName', 'createdAt', 'updatedAt'],
        {
          userEmail: userData?.email,
          userId: userData?.id,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          createdAt: userData?.created_at,
          updatedAt: userData?.updated_at,
        },
      );
    }

    yield put(
      getUserSummarySuccess({
        ...userData,
        roles: 'ROLE_SUPERADMIN',
        permissions: [
          ViewPermissions.ViewCdn,
          ViewPermissions.ViewConfiguration,
          ViewPermissions.ViewDashboard,
          ViewPermissions.ViewHomepage,
          ViewPermissions.ViewProfileSetings,
        ],
      }),
    );
    yield put(appInitSuccess());
  } catch (error: any) {
    console.error('Error fetching user summary:', error);
    if (error.response && error.response.status === 401) {
      yield put(updateToken({ toast: false }));
    } else {
      yield put(appInitError('Error fetching user data'));
    }
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* userSagaWatcher() {
  yield takeLatest(getUserSummary.type, getUserSummarySaga);
}
