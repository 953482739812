import { DocumentMagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import i18n from 'i18next';
import { memo, useMemo } from 'react';

import { ActionHandlers, Button, EmptyObject } from '@components';
import { TableActionHandlerType, VariantType } from '@enums';
import { checkIdProperty, checkNameProperty } from '@utils';

interface ActionTableCellProps<T> {
  multiLines?: boolean;
  data: T;
  tableKey: keyof T;
  actionHandlers?: ActionHandlers<T>;
}

const ActionButton = memo(
  ({
    icon,
    onClick,
    disabled,
    tooltip,
  }: {
    icon: JSX.Element;
    onClick: () => void;
    tooltip: string;
    disabled?: boolean;
  }) => (
    <Button
      hoverEffect={!disabled}
      hint={
        <article className="text-xs p-1 max-w-xs">
          {disabled ? i18n.t('common:functionUnderDevelopment') : tooltip}
        </article>
      }
      variant={VariantType.Icon}
      iconWithoutM
      withoutPadding
      disabled={disabled}
      rounded="rounded-md"
      onClick={onClick}
      className="ring-0 h-fit transition-colors duration-200 ease-in-out"
      icon={icon}
    />
  ),
);

const resolveTooltip = <T extends EmptyObject<T>>(
  actionKey: TableActionHandlerType,
  data: T,
  tableKey: keyof T,
): string => {
  const baseText = data[tableKey] ?? (checkNameProperty(data) && data.name) ?? String(tableKey);
  const action = i18n.t(`common:modals.${actionKey}`, { defaultValue: actionKey });
  return `${action} ${baseText}`;
};

const actionIcons = {
  [TableActionHandlerType.handleEdit]: (disabled: boolean) => (
    <PencilSquareIcon className={`w-4 h-4 ${disabled ? 'text-theme-disabled' : 'text-blue-500'}`} />
  ),
  [TableActionHandlerType.handleView]: (disabled: boolean) => (
    <DocumentMagnifyingGlassIcon className={`w-4 h-4 ${disabled ? 'text-theme-disabled' : 'text-blue-500'}`} />
  ),
  [TableActionHandlerType.handleDelete]: (disabled: boolean) => (
    <TrashIcon className={`w-4 h-4 ${disabled ? 'text-theme-disabled' : 'text-red-500'}`} />
  ),
};

export const ActionTableCell = memo(
  <T extends EmptyObject<T>>({ data, actionHandlers, tableKey, multiLines = false }: ActionTableCellProps<T>) => {
    const disabledActions = useMemo(() => actionHandlers?.handleDisable?.() ?? [], [actionHandlers]) as string[];

    const actionButtons = useMemo(() => {
      if (!actionHandlers) return [];

      return Object.entries(actionHandlers).reduce<JSX.Element[]>((acc, [actionKey, handler]) => {
        if (
          actionKey === TableActionHandlerType.handleCreate ||
          actionKey === TableActionHandlerType.handlePriority ||
          actionKey === TableActionHandlerType.handleVerification ||
          actionKey === TableActionHandlerType.handleCheck ||
          actionKey === TableActionHandlerType.handleDisable
        ) {
          return acc;
        }

        const typedActionKey = actionKey as TableActionHandlerType;
        const isDisabled = disabledActions.includes(actionKey);
        const icon = actionIcons[typedActionKey];

        if (icon && handler && (typedActionKey !== TableActionHandlerType.handleDelete || checkIdProperty(data))) {
          acc.push(
            <ActionButton
              key={actionKey}
              icon={icon(isDisabled)}
              disabled={isDisabled}
              onClick={() => handler(data)}
              tooltip={resolveTooltip(typedActionKey, data, tableKey)}
            />,
          );
        }

        return acc;
      }, []);
    }, [actionHandlers, data, disabledActions, tableKey]);

    if (!actionButtons.length) return null;

    return <div className={`flex gap-x-1 ${multiLines ? 'py-1.5 h-full' : ''}`}>{actionButtons}</div>;
  },
);
