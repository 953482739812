import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionHandlers } from '@/utils';
import { ConfirmationModal } from '@components';
import {
  CdnConfigurationTab,
  StatusType,
  TableActionHandlerType,
  TableActionType,
  originType,
  verificationType,
} from '@enums';
import { useAppDispatch, useResetLoadSuccess } from '@hooks';
import {
  deleteConfigurationData,
  getMultipleRequest,
  useCdnLoading,
  useCdnTableData,
  useConfigurationList,
  verifyDomainName,
} from '@store';
import { useParams } from 'react-router-dom';
import { DomainForm, DomainModal } from './subComponents';

export interface DomainDataProps {
  id?: number;
  domain: string;
  origin?: any;
  type?: originType;
  name: string;
  status?: verificationType;
  connect_timeout?: number;
  send_timeout?: number;
  read_timeout?: number;
  verify_ssl_cert?: boolean;
}

export const Domain = () => {
  const dispatch = useAppDispatch();

  const { id: application_id } = useParams();
  const currentApplicationData = useCdnTableData();
  const applicationDataLoading = useCdnLoading();

  const tableKey = CdnConfigurationTab.Domain;

  const { t: tModal } = useTranslation('configuration', { keyPrefix: 'modals' });
  const { t: tRouteModal } = useTranslation('configuration', { keyPrefix: 'modals.domain' });
  const { t: tCommon } = useTranslation('common');

  const [editApplication, setEditApplication] = useState<boolean>(false);
  const [openDomainModal, setOpenDomainModal] = useState<boolean>(false);
  const [openVerificationModal, setOpenVerificationModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<DomainDataProps>();

  const domainList = useConfigurationList(tableKey);

  const cname = currentApplicationData?.cname ?? '';

  useResetLoadSuccess([tableKey]);

  useEffect(() => {
    if (application_id) {
      dispatch(
        getMultipleRequest({
          tab: CdnConfigurationTab.Origin,
          requests: [{ tableKey, application_id, hasPagination: true }],
        }),
      );
    }
  }, [application_id]);

  const disableHandler =
    !cname || !domainList?.length || domainList.every(({ cname_verified }) => cname_verified)
      ? [TableActionHandlerType.handleVerification]
      : [];

  const domainActionHandlers = useActionHandlers<DomainDataProps>({
    setData: setCurrentData,
    setEditOpen: setOpenDomainModal,
    setVerifyOpen: setOpenVerificationModal,
    setDeleteOpen: setOpenDeleteModal,
    setEdit: setEditApplication,
    tableKey,
    activeHandlers: [
      TableActionType.Create,
      TableActionType.Edit,
      TableActionType.Verification,
      TableActionType.Delete,
      TableActionType.Check,
    ],
    disabledHandlers: disableHandler,
  });

  const handleVerificationClose = () => {
    dispatch(
      verifyDomainName({
        application_id,
        tableKey,
        content_id: currentData?.id,
      }),
    );

    setOpenVerificationModal(false);
  };

  const handleDeleteClose = () => {
    if (currentData?.id) {
      dispatch(deleteConfigurationData({ tableKey, application_id, content_id: currentData.id }));
    }
    setOpenDeleteModal(false);
  };

  return (
    <div className="flex flex-1">
      <DomainForm actionHandlers={domainActionHandlers} />
      <DomainModal
        data={currentData}
        isEdit={editApplication}
        open={openDomainModal}
        onClose={() => setOpenDomainModal(false)}
      />
      <ConfirmationModal
        title={tRouteModal('domainDelete')}
        content={tModal('areYouSureDelete', { domain: currentData?.domain })}
        confirmButton={{ children: tCommon('button.delete'), onClick: handleDeleteClose }}
        open={openDeleteModal}
        type={StatusType.Delete}
        onClose={() => setOpenDeleteModal(false)}
      />
      {currentApplicationData && !applicationDataLoading && openVerificationModal && (
        <ConfirmationModal
          title={tRouteModal('domainVerification')}
          content={`${tModal(currentData ? 'domain.areYouSureVerifyDomain' : 'domain.areYouSureVerifyAllDomain', { domain: currentData?.domain, cname: currentApplicationData?.cname })} ? `}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleVerificationClose }}
          open={openVerificationModal}
          onClose={() => setOpenVerificationModal(false)}
        />
      )}
    </div>
  );
};
