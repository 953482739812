import { TabGroup, TabList } from '@headlessui/react';
import { ShieldCheckIcon } from '@heroicons/react/20/solid';
import {
  ChartPieIcon,
  ComputerDesktopIcon,
  DocumentCheckIcon,
  FolderIcon,
  InboxArrowDownIcon,
  ScaleIcon,
  SignalIcon,
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  addSnack,
  resetConfigurationForm,
  submitConfigurationForm,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@/store';
import { Button, GlobalLoader, Responsive, Text } from '@components';
import { ROUTES } from '@constants';
import { CdnConfigurationTab, StatusType, VariantType } from '@enums';
import { useAppDispatch, useBreadcrumbItems } from '@hooks';
import { useCdnData } from '@store';

export const SideNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: application_id } = useParams();
  const tableKey = CdnConfigurationTab.Configuration;
  const { t: tSide } = useTranslation('common', { keyPrefix: 'configurationSide' });
  const { t: translate } = useTranslation('common');
  const { t: tConfiguration } = useTranslation('configuration');
  const { pathname } = useLocation();
  const cdnData = useCdnData();

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  const breadcrumbItems = useBreadcrumbItems(pathname, cdnData, translate);

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      dispatch(
        addSnack({
          type: StatusType.Success,
          message: tConfiguration('deploySuccessful', { name: breadcrumbItems?.[1].name ?? '' }),
        }),
      );
      dispatch(resetConfigurationForm(tableKey));
    }
  }, [
    application_id,
    breadcrumbItems,
    dispatch,
    isSubmitting,
    navigate,
    saveSuccess,
    submitError,
    tConfiguration,
    tableKey,
  ]);

  const navigation = [
    {
      name: tSide('dashboard'),
      href: ROUTES.configuration.dashboard,
      icon: ChartPieIcon,
      count: '5',
      current: false,
      background: 'bg-slate-500',
    },
    {
      name: tSide('domain'),
      href: ROUTES.configuration.domain,
      icon: ComputerDesktopIcon,
      current: true,
      background: 'bg-red-400',
    },
    {
      name: tSide('origins'),
      href: ROUTES.configuration.origins,
      icon: FolderIcon,
      count: '12',
      current: false,
      background: 'bg-orange-400',
    },
    {
      name: tSide('ssl'),
      href: ROUTES.configuration.ssl,
      icon: DocumentCheckIcon,
      count: '20+',
      current: false,
      background: 'bg-green-500',
    },
    {
      name: tSide('waf'),
      href: ROUTES.configuration.waf.main,
      icon: SignalIcon,
      current: false,
      background: 'bg-blue-300',
    },
    {
      name: tSide('security'),
      href: ROUTES.configuration.security,
      icon: ShieldCheckIcon,
      current: false,
      background: 'bg-sky-400',
    },
    {
      name: tSide('pageRules'),
      href: ROUTES.configuration.pagerules,
      icon: ScaleIcon,
      current: false,
      background: 'bg-indigo-400',
    },
    {
      name: tSide('cache'),
      href: ROUTES.configuration.cache,
      icon: InboxArrowDownIcon,
      current: false,
      background: 'bg-slate-700',
    },
  ];

  navigation?.forEach((item) => {
    if (pathname.includes(item.href)) {
      item.current = true;
    } else item.current = false;
  });

  const handleDeploy = () => {
    dispatch(
      submitConfigurationForm({
        formData: { application_id },
        tableKey,
        disabledToast: true,
        ignoreResponse: true,
      }),
    );
  };

  const handleNavigateButton = (location) => {
    navigate(location);
  };
  return (
    <>
      <nav className="flex flex-col gap-7 h-full" aria-label="Sidebar">
        <div className="flex flex-row lg:flex-col gap-7 justify-between items-end lg:items-start">
          <div className="flex flex-col gap-3">
            <Text $level={3} color="text-black" className="font-medium">
              {tSide('serverSetup')}
            </Text>
            <Text $level={5} color="text-gray-400" className=" font-light">
              {tSide('configurationNavigation')}
            </Text>
          </div>
          <div className="lg:w-full">
            <Button
              customizeColor
              className="bg-green-400 bg-opacity-80 text-white shadow-lg"
              onClick={handleDeploy}
              fullWidth
            >
              {tSide('deploy')}
            </Button>
          </div>
        </div>
        <Responsive showAbove="lg">
          <div className="flex flex-1 mt-1">
            <ul className="flex-col flex flex-1 space-y-2 py-4 bg-white bg-opacity-90 shadow-card rounded-2xl">
              <article className="text-gray-400 text-xs font-light px-5 pt-2 pb-3">
                {tSide('configurationNavigator')}
              </article>
              {navigation.map((item) => (
                <li key={item.name} className="flex items-center">
                  <Button
                    withoutCenter
                    withoutPadding
                    variant={VariantType.Transparent}
                    onClick={() => handleNavigateButton(item.href)}
                    rounded="rounded-none"
                    className={`${
                      item.current
                        ? ' text-slate-700 bg-slate-500 bg-opacity-5 font-medium'
                        : 'bg-transparent text-slate-400 hover:text-slate-700 shadow-none font-normal'
                    } px-3.5 items-center gap-x-3 text-xs py-2 flex flex-1 relative`}
                  >
                    {item.current && (
                      <div className="absolute w-1 h-5/6 -mx-3.5 bg-theme-primary-main rounded-full"></div>
                    )}
                    <div className={`p-2 rounded-lg text-white ${item.background}`}>
                      <item.icon className={'h-4 w-4 shrink-0'} aria-hidden="true" />
                    </div>
                    <article className="text-justify">{item.name}</article>
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </Responsive>
        <Responsive showBelow="lg">
          <TabGroup>
            <TabList className="flex gap-4 border-b pb-2 max-w-full overflow-x-auto hidden-scrollbar">
              {navigation.map((item) => (
                <li key={item.name} className="flex items-center">
                  <Button
                    withoutCenter
                    withoutPadding
                    variant={VariantType.Transparent}
                    onClick={() => handleNavigateButton(item.href)}
                    rounded="rounded-lg"
                    className={`${
                      item.current
                        ? ' text-slate-700 bg-slate-500 bg-opacity-5 font-medium'
                        : 'bg-transparent text-slate-400 hover:text-slate-700 shadow-none font-normal'
                    } px-3.5 items-center gap-x-3 text-xs py-3 flex flex-1 relative`}
                  >
                    <article className="text-justify">{item.name}</article>
                  </Button>
                </li>
              ))}
            </TabList>
          </TabGroup>
        </Responsive>
      </nav>
      {isSubmitting && (
        <div className="fixed inset-0 bg-white bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50">
          <GlobalLoader />
        </div>
      )}
    </>
  );
};
