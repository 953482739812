import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useField } from 'formik';
import { InputHTMLAttributes, useRef } from 'react';
import { Button } from '../Buttons';

interface AreaInputProps extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  adornment?: string;
  name: string;
  className?: string;
  isCorrect?: boolean;
  isfilled?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  smallFont?: boolean;
  rows?: number;
  uploadLabel?: string;
}

export const TextAreaInput: React.FC<AreaInputProps> = ({
  name,
  className = '',
  placeholder,
  isCorrect,
  fullWidth,
  value,
  disabled,
  uploadLabel,
  smallFont,
  rows = 4,
}: AreaInputProps) => {
  const [field, meta, helpers] = useField(name);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isError = meta.error && meta.touched;

  const dynamicClasses = classNames(
    'block w-full rounded-md border-0  placeholder-gray-400  ring-1 focus:ring-offset-2 sm:leading-6 padded-scrollbar-track',
    {
      'pr-20': isCorrect,
      'text-us': smallFont,
      'text-sm': !smallFont,
      'bg-theme-primary-light': disabled,
      'ring-theme-error-main bg-red-50 focus:ring-theme-error-main': isError,
      'ring-theme-border-default focus:ring-theme-icon-lightBlue': !isError,
      [className]: className,
    },
  );

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/plain') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e?.target?.result ?? '';
        helpers.setValue(text);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className={`relative ${fullWidth ? 'w-full' : ''}`}>
      <textarea
        {...field}
        autoComplete="off"
        id={name}
        rows={rows}
        value={value ?? field.value ?? ''}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        aria-invalid={!!(meta.error && meta.touched)}
        aria-describedby={isError ? `${name}-error` : undefined}
        className={dynamicClasses}
      />
      {uploadLabel && (
        <div className="flex flex-col items-center pt-4">
          <Button smallFont fullWidth onClick={() => fileInputRef.current?.click()}>
            <article>{uploadLabel}</article>
          </Button>
          <input ref={fileInputRef} type="file" accept=".txt" onChange={handleFileUpload} className="hidden" />
        </div>
      )}

      {isError && (
        <div className={`mt-4 text-xs flex text-red-600`} id={`${name}-error`} role="alert">
          <ExclamationTriangleIcon className="min-w-4 min-h-4 max-w-4 max-h-4 mt-0.5 pointer-events-none" />
          <article className="pl-2 text-us break-all text-justify">{meta.error}</article>
        </div>
      )}
    </div>
  );
};
