import { TableActionHandlerType } from '@/enums';
import { checkIdProperty } from '@/utils';
import { useSelectedConfigurationData } from '@store';
import { ActionHandlers } from '../DraggableTable';
import { EmptyObject } from '@/components/Modals';

interface CheckBoxTableCellDefaultProps<T> {
  tableKey: string;
  data: T;
  actionHandlers: ActionHandlers<T>;
}

export const CheckBoxTableCell = <T extends EmptyObject<T>>({
  tableKey,
  data,
  actionHandlers,
}: CheckBoxTableCellDefaultProps<T>) => {
  const selectedData = useSelectedConfigurationData(tableKey);

  if (!checkIdProperty(data)) return;
  return (
    <input
      type="checkbox"
      checked={selectedData?.includes(data.id) ?? false}
      onChange={() => actionHandlers[TableActionHandlerType.handleCheck]?.(tableKey, data.id)}
      className="h-3.5 w-3.5 mx-3 rounded border-gray-300 text-indigo-600 focus:ring-0 hover:cursor-pointer"
    />
  );
};
