import { HTMLAttributes, memo, useMemo } from 'react';

import { CellType } from '@enums';

import { PaginationProps } from '@/types';
import {
  ActionHandlers,
  ActionTableCell,
  BadgeTableCell,
  CheckBoxTableCell,
  Column,
  DefaultTableCell,
  EmptyObject,
} from '@components';

interface DraggableTableCellProps<T> extends HTMLAttributes<HTMLTableCellElement> {
  sticky?: boolean;
  cellType?: CellType;
  column: Column;
  row: T;
  colId: number;
  width?: number;
  maxWidth?: number | string;
  minWidth?: number | string;
  format?: (value: T, fullData: T) => any;
  dotsTextOverflow?: boolean;
  actionHandlers: ActionHandlers<T>;
  isDraggable?: boolean;
  tableKey: string;
  rowId: number;
  pagination?: PaginationProps;
}

export const DraggableTableCell = memo(
  <T extends EmptyObject<T>>({
    sticky = false,
    cellType = CellType.Text,
    row,
    width = 70,
    minWidth,
    maxWidth,
    column,
    format = (data) => data,
    className = '',
    dotsTextOverflow = true,
    actionHandlers,
    isDraggable,
    tableKey,
    rowId,
    colId,
    pagination,
  }: DraggableTableCellProps<T>) => {
    const cellValue = Array.isArray(column.value)
      ? column.value.map((value) => row[value]).join(', ')
      : row[column.value];
    const renderCell = (cellType: CellType) => {
      const formattedData = format(cellValue, row);
      switch (cellType) {
        case CellType.Empty:
          return null;
        case CellType.Custom:
          return column?.format?.(cellValue, row);
        case CellType.Badge:
          return <BadgeTableCell tableKey={tableKey} data={formattedData} rawData={cellValue} />;
        case CellType.Action:
          return (
            <ActionTableCell
              tableKey={tableKey}
              multiLines={column.multiLines}
              actionHandlers={actionHandlers}
              data={row}
            />
          );

        case CellType.Checkbox:
          return <CheckBoxTableCell data={row} tableKey={tableKey} actionHandlers={actionHandlers} />;
        default:
          return (
            <DefaultTableCell
              tableKey={tableKey}
              rowId={rowId}
              data={formattedData}
              column={column}
              pagination={pagination}
              multiLines={column.multiLines}
            />
          );
      }
    };
    const isCollapseControl = useMemo(() => cellType === CellType.CollapseControl, [cellType]);
    const isActionTable = useMemo(() => cellType === CellType.Action, [cellType]);
    return (
      <td
        key={colId}
        className={`
        text-sm font-medium text-gray-900
        ${column.center ? 'text-center' : ''} 
          ${!isActionTable ? 'px-3 py-3.5' : ' align-middle'} h-4 min-h-4 text-left
        ${isDraggable ? 'cursor-grab active:cursor-grabbing' : ''}
        ${dotsTextOverflow ? 'whitespace-break-spaces' : 'whitespace-preline'}
        ${dotsTextOverflow ? 'overflow-hidden' : 'overflow-visible'}
        ${dotsTextOverflow ?? isCollapseControl ? 'text-ellipsis' : 'text-initial'}
        ${dotsTextOverflow ? 'break-normal' : 'break-all'}
        ${sticky ? 'sticky' : ''}
        ${className}
      `}
        style={{ width: width, minWidth: minWidth, maxWidth: maxWidth }}
      >
        {renderCell(cellType)}
      </td>
    );
  },
);
