import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getWAFConditionsColumns } from '@/constants';
import { Button, Cards, FormField } from '@components';
import {
  ConditionOperatorType,
  ConditionVariableType,
  InputType,
  StatusType,
  VariantType,
  WAFActionType,
  WAFRulesType,
  columnDirection,
  columnSpace,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { addSnack, getCurrentContent, useCurrentData } from '@store';
import { Dictionaries, getOptionListFromCatalog } from '@utils';

export const CustomWAFRules = () => {
  const { t: tForm } = useTranslation('configuration', { keyPrefix: 'forms.waf.custom' });
  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });
  const { t: tToast } = useTranslation('configuration', { keyPrefix: 'toasts.waf' });
  const { t: tPredefinedForm } = useTranslation('configuration', { keyPrefix: 'forms.waf.predefined' });

  const CustomWafData = useCurrentData('waf');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const segments = location.pathname.split('/');
  const backtoWAF = segments.slice(0, -1).join('/');
  const hasCustomWafData = Object.keys(CustomWafData).length > 0;
  const handleSubmit = (values) => {
    console.log(values);

    navigate(backtoWAF);
    dispatch(
      addSnack({
        type: StatusType.Success,
        message: hasCustomWafData ? tToast('createWAFSuccessful') : tToast('editWAFSuccessful'),
      }),
    );
  };

  const WAFConditionTableColumns = useMemo(getWAFConditionsColumns, []);

  const defaultFormFieldProps = {
    direction: columnDirection.Row,
    smallFont: true,
    columnPercentage: columnSpace.Half,
    fullWidth: true,
    required: true,
  };

  return (
    <div className="p-1">
      <Cards className="flex flex-1 flex-col w-full" rounded="rounded-2xl">
        <Formik
          initialValues={{
            name: CustomWafData?.name ?? '',
            blockAction: CustomWafData?.blockAction ?? WAFActionType.Log,
            wafRules: CustomWafData?.wafRules ?? [{ value: WAFRulesType.scanner_detection }],
            conditions: CustomWafData?.conditions ?? [],
            crossRequestMode: CustomWafData?.crossRequestMode ?? false,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors }) => (
            <Form className="bg-white rounded-lg py-3 space-y-8">
              <article className="text-lg font-medium leading-6 text-gray-700">
                {tForm(hasCustomWafData ? 'editWaf' : 'createNewWaf')}
              </article>
              <div className="grid grid-cols-1 gap-y-6 pt-3">
                <FormField
                  {...defaultFormFieldProps}
                  label={tForm('customWafRulesName')}
                  name="name"
                  placeholder={tForm('enterYourCustomWafRulesName')}
                  isCorrect={touched.name && !errors.name}
                />

                <FormField
                  {...defaultFormFieldProps}
                  direction={columnDirection.Column}
                  inputType={InputType.Table}
                  withoutLabel
                  label={tForm('conditions')}
                  columns={WAFConditionTableColumns}
                  name="conditions"
                  placeholder={''}
                  emptyData={{
                    main: {
                      field: ConditionVariableType.URI,
                      argument: '',
                      operator: ConditionOperatorType.stringEqual,
                      values: [{ value: '' }],
                    },
                  }}
                />
                <FormField
                  {...defaultFormFieldProps}
                  inputType={InputType.Radio}
                  label={tForm('wafRules')}
                  columns={WAFConditionTableColumns}
                  direction={columnDirection.Row}
                  name="wafRules"
                  placeholder={''}
                />

                <FormField
                  {...defaultFormFieldProps}
                  label={tForm('blockAction')}
                  direction={columnDirection.Row}
                  name="blockAction"
                  inputType={InputType.Select}
                  placeholder={tForm('selectYourBlockAction')}
                  options={getOptionListFromCatalog(Dictionaries.WAFAction)}
                />
              </div>
              <FormField
                {...defaultFormFieldProps}
                inputType={InputType.Switch}
                label={tPredefinedForm('crossRequestMode')}
                name="crossRequestMode"
                placeholder={''}
                description={tPredefinedForm('crossRequestHints')}
              />
              <div className="sm:grid sm:grid-cols-2 gap-8">
                <Button
                  variant={VariantType.Outlined}
                  onClick={() => {
                    dispatch(getCurrentContent({ tableKey: 'waf', rowData: {} }));
                    navigate(backtoWAF);
                  }}
                  className="rounded-md ring-theme-primary-main"
                >
                  <article className="ellipsis-text">{tButton('cancel')}</article>
                </Button>
                <Button type="submit">{tButton(CustomWafData ? 'save' : 'create')}</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Cards>
    </div>
  );
};
