import { Disclosure, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, Bars3Icon, GlobeAltIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Formik } from 'formik';
import i18n from 'i18next';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Responsive, SelectBox, Skeleton, Text } from '@components';
import { ROUTES } from '@constants';
import { Language, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import { logOut, updateWorkSpaceId, useUserDetails, useWorkspaceList, useWorkspaceLoading } from '@store';
import { capitalize, Dictionaries, getOptionListFromCatalog } from '@utils';

import { initData, updateDataInStorage, workSpaceId } from '@/storage';

export const Header = ({ sidebarAction }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tAll } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const path = useLocation();
  const userDetails = useUserDetails();
  const workSpaceList = useWorkspaceList();
  const workspaceLoading = useWorkspaceLoading();
  const init = initData.getItem();

  const currentWorkspaceId = workSpaceId.getItem();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const pageDetails = (path: { pathname: string }) => {
    const { pathname } = path;
    for (const key in ROUTES) {
      if (Object.hasOwn(ROUTES, key)) {
        const route = ROUTES[key];
        if (typeof route === 'string' && route === pathname) {
          return pathname.slice(1);
        }
      }
    }
    return 'configuration';
  };

  const languageOption = useMemo(() => getOptionListFromCatalog(Dictionaries.Languages), [tAll]);
  const workspaceOptions = useMemo(
    () =>
      workSpaceList?.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [tAll, workspaceLoading],
  );

  const layoutTitleRow = () => {
    return (
      <div className="flex flex-col gap-4 sm:gap-4 w-full xl:w-2/5 ">
        <Text $level={3} color="text-theme-text-title" className=" font-semibold  ">
          {tAll(`${pageDetails(path)}:title`)}
        </Text>
        <Text $level={5} color="text-gray-400" className="font-medium text-justify leading-4">
          {tAll(`${pageDetails(path)}:description`, {
            name: capitalize(`${userDetails?.first_name} ${userDetails?.last_name}`),
          })}
        </Text>
      </div>
    );
  };

  return (
    <Disclosure as="nav" className={'pb-8'}>
      {() => (
        <div className="flex flex-col gap-8">
          <div className={`flex flex-row w-full gap-3 items-center relative`}>
            <Responsive showBelow="xl">
              <div className="h-full justify-start flex">
                <Button
                  iconWithoutM
                  withoutScale
                  withoutCenter
                  variant={VariantType.Transparent}
                  hoverEffect
                  className={`text-theme-default p-3`}
                  withoutPadding
                  onClick={() => sidebarAction(true)}
                >
                  <Bars3Icon className="h-5 w-5 text-black" aria-hidden="true" />
                </Button>
              </div>
            </Responsive>
            <Responsive showAbove="xl">{layoutTitleRow()}</Responsive>
            <Menu as="div" className="flex flex-1 justify-end">
              <div className="flex items-center gap-3 md:gap-6">
                {workspaceLoading && workspaceOptions.length <= 0 ? (
                  <Skeleton className="w-4 h-4" />
                ) : (
                  <Formik
                    initialValues={{
                      language: init?.language ?? Language.English,
                      workspace_id: currentWorkspaceId,
                    }}
                    onSubmit={() => {}}
                  >
                    {() => {
                      const handleChangeLanguage = (option) => {
                        if (option.value) {
                          i18n.changeLanguage(option.value);
                          updateDataInStorage(initData, ['language'], {
                            language: option.value,
                          });
                        }
                      };
                      const handleChangeWorkspace = (option) => {
                        if (option.value) {
                          dispatch(updateWorkSpaceId(option.value));
                        }
                      };
                      return (
                        <>
                          <SelectBox
                            name="language"
                            options={languageOption}
                            anchorDirection="bottom start"
                            withoutPadding
                            customMenu={
                              <div className="flex gap-1 items-center">
                                <GlobeAltIcon className="size-3 sm:size-4 text-gray-600" />
                                <ChevronUpDownIcon className="size-4 sm:size-5 text-gray-400" />
                              </div>
                            }
                            smallFont
                            onOptionSelect={handleChangeLanguage}
                          />
                          <SelectBox
                            name="workspace_id"
                            options={workspaceOptions}
                            anchorDirection="bottom start"
                            withoutPadding
                            smallFont
                            onOptionSelect={handleChangeWorkspace}
                          />
                        </>
                      );
                    }}
                  </Formik>
                )}
                <Button
                  className="p-3 size-12"
                  rounded="rounded-full"
                  hoverEffect
                  selected={isFullScreen}
                  withoutHover
                  variant={VariantType.Transparent}
                  onClick={toggleFullScreen}
                  icon={
                    isFullScreen ? (
                      <ArrowsPointingInIcon className="h-5 w-5 sm:h-6 sm:w-6" />
                    ) : (
                      <ArrowsPointingOutIcon className="h-5 w-5 sm:h-6 sm:w-6" />
                    )
                  }
                  withoutPadding
                  iconWithoutM
                />
                <MenuButton>
                  <UserCircleIcon className="text-gray-500 h-6 w-6 sm:h-8 sm:w-8 " />
                </MenuButton>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1 scale-95"
                enterTo="opacity-100 translate-y-0 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 translate-y-0 scale-100"
                leaveTo="opacity-0 translate-y-1 scale-95"
              >
                <MenuItems className="absolute top-10 right-0 mt-4 w-40 origin-top-right text-xs rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <MenuItem>
                      {({ focus }) => (
                        <Button
                          onClick={() => navigate('/profileSettings')}
                          variant={VariantType.Clean}
                          withoutCenter
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : '',
                            'block w-full text-left px-3 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900',
                          )}
                        >
                          {tCommon('button.yourProfile')}
                        </Button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ focus }) => (
                        <Button
                          onClick={() => dispatch(logOut())}
                          variant={VariantType.Clean}
                          withoutCenter
                          className={classNames(
                            focus ? 'bg-gray-100 text-gray-900' : '',
                            'block w-full text-left px-3 py-2 font-medium text-red-600 hover:bg-gray-100 hover:text-gray-900',
                          )}
                        >
                          {tCommon('button.signOut')}
                        </Button>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
          <Responsive showBelow="xl">{layoutTitleRow()}</Responsive>
        </div>
      )}
    </Disclosure>
  );
};
