import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Cards, DraggableTable, Text } from '@/components';
import { getDashboardTrafficLogsColumns } from '@/constants/components/cdnDashboardTable';
import { CdnConfigurationDashboardGraph, CdnConfigurationTab, VariantType } from '@/enums';
import { useAppDispatch } from '@/hooks';
import { workSpaceId } from '@/storage';
import {
  getDashboardData,
  updateAutoReload,
  useConfigurationAutoReload,
  useConfigurationDashboardData,
  useConfigurationDashboardLastUpdated,
  useConfigurationFilterList,
} from '@/store';
import { formatUnixTimestamp, getGraphTypeValue } from '@/utils';
import { useTranslation } from 'react-i18next';

export const LogPage = () => {
  const dispatch = useAppDispatch();

  const { id: application_id } = useParams();

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.dashboard' });
  const { t: tTables } = useTranslation('description', { keyPrefix: 'configuration.tables' });

  const dashboardLogTableColumns = useMemo(getDashboardTrafficLogsColumns, [tTables]);

  const workspace_id = workSpaceId.getItem();

  const dashboardData = useConfigurationDashboardData();
  const lastUpdated = useConfigurationDashboardLastUpdated({ tableKey: CdnConfigurationTab.Dashboard });
  const loggedData = getGraphTypeValue(dashboardData, CdnConfigurationDashboardGraph.TrafficLog);
  const filterList = useConfigurationFilterList({ tableKey: CdnConfigurationTab.Dashboard });

  const formattedLastUpdated = formatUnixTimestamp(lastUpdated);

  const dashboardAutoReload = useConfigurationAutoReload(CdnConfigurationTab.Dashboard) ?? true;

  const logFilters = useMemo(
    () => filterList.filter((filter) => filter.origin === CdnConfigurationDashboardGraph.TrafficLog),
    [filterList],
  );

  const handleAutoReload = useCallback(() => {
    const newAutoReloadStatus = !dashboardAutoReload;
    dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: newAutoReloadStatus }));
    if (newAutoReloadStatus) {
      dispatch(
        getDashboardData({
          tableKey: CdnConfigurationTab.Dashboard,
          origin: CdnConfigurationDashboardGraph.TrafficLog,
          graphTypeKey: CdnConfigurationDashboardGraph.TrafficLog,
          application_id,
        }),
      );
    }
  }, [dashboardAutoReload, dispatch, application_id, workspace_id]);

  useEffect(() => {
    if (logFilters) {
      dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: true, clear: false }));
      dispatch(
        getDashboardData({
          tableKey: CdnConfigurationTab.Dashboard,
          origin: CdnConfigurationDashboardGraph.TrafficLog,
          graphTypeKey: CdnConfigurationDashboardGraph.TrafficLog,
          application_id,
        }),
      );
    }
    return () => {
      dispatch(updateAutoReload({ tableKey: CdnConfigurationTab.Dashboard, status: false, clear: true }));
    };
  }, [logFilters, application_id]);

  return (
    <Cards rounded="rounded-2xl px-8 py-7 space-y-6" withoutPadding>
      <div className="flex justify-between">
        <article className="font-medium text-theme-primary-main">{tTabs('trafficLogs')}</article>
        <div className="flex gap-5 items-center">
          <Text className="" color="text-gray-500" $level={6}>
            {tTabs('lastUpdated', { date: formattedLastUpdated })}
          </Text>
          <Button
            onClick={handleAutoReload}
            variant={VariantType.Icon}
            icon={
              <ArrowPathIcon
                className={`w-4 h-4 ${dashboardAutoReload ? 'animate-spin-gradual text-theme-primary-main' : 'text-zinc-300'}`}
              />
            }
          />
        </div>
      </div>
      <DraggableTable
        rows={loggedData}
        columns={dashboardLogTableColumns}
        isDataLoading={false}
        tableKey="dashboard"
        actionHandlers={{}}
        onChangePage={() => console.log('hi')}
      />
    </Cards>
  );
};
