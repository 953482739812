import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@store';
import { App } from './App';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import './assets/styles/fonts.css';

const enhancePassiveListeners = () => {
  const originalAddEventListener = EventTarget.prototype.addEventListener;
  EventTarget.prototype.addEventListener = function (type, listener, options) {
    const passiveEvents = ['wheel', 'touchstart', 'touchmove'];
    if (passiveEvents.includes(type)) {
      if (typeof options === 'object') {
        options.passive = true;
      } else if (options === undefined) {
        options = { passive: true };
      }
    }
    originalAddEventListener.call(this, type, listener, options);
  };
};

enhancePassiveListeners();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <App />
  </Provider>,
);
