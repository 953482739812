import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../Buttons';

import { Skeleton } from '@components';
import { VariantType } from '@enums';
import { useAppDispatch, useBreadcrumbItems } from '@hooks';
import { getCdnList, selectCurrentCdnData, useCdnData, useCdnLoading, useCdnLoadSuccess } from '@store';
import { validateValueEqual } from '@utils';

export const Breadcrumbs = ({ theme = 'dark' }: { theme?: 'light' | 'dark' }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const { id } = useParams();

  const cdnData = useCdnData();
  const cdnLoading = useCdnLoading();
  const cdnDataLoadSuccess = useCdnLoadSuccess();

  const { t: translate } = useTranslation('common');

  const breadcrumbItems = useBreadcrumbItems(pathname, cdnData, translate);

  useEffect(() => {
    if (pathname.includes('cdn') && !cdnDataLoadSuccess && !cdnLoading) {
      dispatch(getCdnList());
    }
  }, []);

  useEffect(() => {
    if (pathname.includes('cdn') && id) {
      const matchingCdnData = cdnData.find((cdnData) => validateValueEqual(cdnData?.id?.toString(), id?.toString()));
      if (matchingCdnData) {
        dispatch(selectCurrentCdnData({ rowData: matchingCdnData }));
      }
    }
  }, [cdnData]);

  const generateHref = (index: number) =>
    `/${breadcrumbItems
      .slice(0, index + 1)
      .map((bi) => bi.id ?? bi.name)
      .join('/')}`;

  if (cdnLoading) return <Skeleton className="h-6 w-24" />;
  return (
    <nav
      className={`flex  bg-opacity-90 w-full  rounded-xl ${theme === 'dark' ? 'text-theme-primary-main' : 'text-white'}`}
      aria-label="Breadcrumb"
    >
      <ol className="flex items-center space-x-3">
        <li className="flex gap-x-3">
          <Button
            onClick={() => navigate('/')}
            withoutPadding
            iconWithoutM
            variant={VariantType.Transparent}
            withoutScale
            customizeColor
            className="text-sm hover:cursor-default text-gray-600 hover:text-gray-500"
            icon={<HomeIcon className="w-5 h-5" />}
          />
        </li>
        {breadcrumbItems.map((item, index) => (
          <li key={index} className="flex items-center gap-x-3">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <Button
              onClick={() => navigate(generateHref(index))}
              withoutPadding
              variant={VariantType.Transparent}
              disabled={index === breadcrumbItems.length - 1}
              className={`text-sm ${index === breadcrumbItems.length - 1 ? 'text-gray-400 font-medium' : 'font-normal text-gray-600 hover:font-medium hover:text-gray-500'}`}
            >
              {item.name}
            </Button>
          </li>
        ))}
      </ol>
    </nav>
  );
};
