import { DialogPanel } from '@headlessui/react';

import {
  ActionHandlers,
  Button,
  Column,
  DialogModal,
  DraggableTable,
  ModalActions,
  ModalBody,
  ModalDescription,
  ModalTitle,
} from '@components';
import { TableActionHandlerType, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getConfigurationList,
  resetConfigurationForm,
  useConfigurationError,
  useConfigurationLoading,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
  useConfigurationTableData,
} from '@store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export type EmptyObject<T> = Record<keyof T, T>;

interface PrioritizeModalProps<T> {
  columns: Column[];
  open: boolean;
  onClose: () => void;
  actionHandlers: ActionHandlers<T>;
  title: string;
  description?: string;
  tableKey?: string;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
}

export const PrioritizeModal = <T extends EmptyObject<T>>({
  title,
  description,
  columns,
  tableKey = '',
  actionHandlers,
  size = 'lg',
  open,
  className = 'min-w-[40rem]',
  onClose,
}: PrioritizeModalProps<T>) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });

  const sortListData = useConfigurationTableData(tableKey);
  const sortListLoading = useConfigurationLoading(tableKey);
  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
    }
  }, [isSubmitting, saveSuccess, submitError]);

  useEffect(() => {
    if (application_id) {
      dispatch(getConfigurationList({ tableKey, application_id, sorting: true }));
    }
  }, [application_id]);

  const onSaveHandler = () => {
    if (actionHandlers[TableActionHandlerType.handlePriority]) {
      actionHandlers[TableActionHandlerType.handlePriority](tableKey, application_id);
    }
  };

  if (!open || !tableKey) return;

  return (
    <DialogModal notForm open={open} onClose={onClose} size={size}>
      <DialogPanel>
        {title && typeof title !== 'string' ? title : <ModalTitle className="font-medium">{title}</ModalTitle>}
        {!description && typeof description !== 'string' ? (
          description
        ) : (
          <ModalDescription className="text-gray-500 w-full">{description}</ModalDescription>
        )}
        <ModalBody notForm className="max-w-full mt-8 sm:mt-11">
          <DraggableTable
            rows={sortListData}
            columns={columns}
            isDataLoading={sortListLoading}
            tableKey={tableKey}
            isDraggable={true}
            actionHandlers={actionHandlers}
          />
          <ModalActions>
            <Button
              variant={VariantType.Outlined}
              loading={isSubmitting}
              className="rounded-md ring-theme-primary-main"
              onClick={onClose}
            >
              {tButton('cancel')}
            </Button>
            <Button loading={isSubmitting} onClick={onSaveHandler}>
              {tButton('save')}
            </Button>
          </ModalActions>
        </ModalBody>
      </DialogPanel>
    </DialogModal>
  );
};
