import { FC, Fragment, ReactNode, useMemo } from 'react';

type MultilineTextProps = {
  text: any;
  className?: string;
};

const isString = (value: any): value is string => typeof value === 'string' || value instanceof String;

const processMultilineText = (text: any): ReactNode => {
  if (!isString(text)) {
    return text;
  }

  try {
    return text.split('\n').map((line, index, array) => (
      <Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </Fragment>
    ));
  } catch (error) {
    return text;
  }
};

export const MultilineText: FC<MultilineTextProps> = ({ text, className }) => {
  const processedText = useMemo(() => processMultilineText(text), [text]);

  return <span className={className}>{processedText}</span>;
};
