import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionHandlers, Button, ConfirmationModal, getColorMapping } from '@components';
import { StatusType } from '@enums';
import { useAppDispatch } from '@hooks';
import { CdnData, CdnStatus } from '@pages';
import { addSnack, getCdnList, useCdnLoading } from '@store';

type CdnCardsProps = {
  data: CdnData;
  actionHandlers: ActionHandlers<any>;
};

export const CdnCards = ({ data, actionHandlers }: CdnCardsProps) => {
  const dispatch = useAppDispatch();

  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });
  const { t: tCdnModal } = useTranslation('cdn', { keyPrefix: 'modals' });
  const { t: tCdnToast } = useTranslation('cdn', { keyPrefix: 'toasts' });
  const { t: tValidationServer } = useTranslation('validation', { keyPrefix: 'server' });
  const { t: tCommon } = useTranslation('common');

  const loading = useCdnLoading();

  const [openDeactivateDialog, setOpenDeactivateDialog] = useState<boolean>(false);

  const statusType = data.status === CdnStatus.Active ? StatusType.Info : StatusType.Error;
  const colorMapping = getColorMapping(statusType);
  const IconComponent = colorMapping.icon;

  const handleDeactivateDialog = () => {
    setOpenDeactivateDialog(!openDeactivateDialog);
  };

  const renderStatus = (cellType: CdnStatus) => {
    switch (cellType) {
      case CdnStatus.Active:
        return tValidationServer('isWorkingFine');
      case CdnStatus.InActive:
        return tValidationServer('isDeactivated');
      default:
        return null;
    }
  };

  const handleDeactivateClose = () => {
    dispatch(
      addSnack({
        type: StatusType.Success,
        message: tCdnToast('deactivateSuccessful', { applicationName: data?.name }),
      }),
    );
    dispatch(getCdnList());
    handleDeactivateDialog();
  };

  return (
    <dl className="flex flex-1 flex-col p-6 relative space-y-4  w-full">
      <div className="flex justify-between flex-1">
        <div className="overflow-hidden whitespace-nowrap">
          <article className="text-lg font-medium truncate">{data.name}</article>
        </div>
        <Tippy content={<article className="text-us">{renderStatus(data?.status)}</article>} placement="top">
          <div className="hover:cursor-pointer">
            <IconComponent className="h-8 w-8" />
          </div>
        </Tippy>
      </div>
      <dd className="flex justify-between items-center">
        <article className="text-gray-400 font-light text-xs">{renderStatus(data?.status)}</article>
      </dd>

      <div className="flex pt-2 gap-x-3">
        <Button
          fullWidth
          className="py-2"
          rounded="rounded-2xl"
          withoutPadding
          onClick={() => {
            data && actionHandlers.handleEdit && actionHandlers.handleEdit(data.id);
          }}
          disabled={loading}
        >
          <article className="ellipsis-text text-us">{tButton('view')}</article>
        </Button>
        {/* <Button
          withoutPadding
          rounded="rounded-2xl"
          disabled={loading}
          customizeColor
          variant={VariantType.Outlined}
          fullWidth
          onClick={handleDeactivateDialog}
          className={`ring-1 ${loading ? 'text-gray-600 ring-gray-200' : 'text-pink-600 ring-pink-600 '}`}
        >
          <article className="ellipsis-text text-us">{tButton('deactivate')}</article>
        </Button> */}
      </div>
      {openDeactivateDialog && (
        <ConfirmationModal
          title={tCdnModal('deactivateApplication', { applicationName: data?.name })}
          content={tCdnModal('areYouSureDeactivate', { applicationName: data?.name })}
          confirmButton={{ children: tCommon('button.confirm'), onClick: handleDeactivateClose }}
          open={openDeactivateDialog}
          type={StatusType.Delete}
          onClose={handleDeactivateDialog}
        />
      )}
    </dl>
  );
};
