import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPageRulesPriorityTableColumns, getPageRulesTableColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { ActionHandlers, ConfigurationCard, DraggableTable, EmptyObject, PrioritizeModal } from '@components';
import { useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@store';

interface SSLFormProps<T> {
  actionHandlers: ActionHandlers<T>;
}

export const PageRulesForm = <T extends EmptyObject<T>>({ actionHandlers }: SSLFormProps<T>) => {
  const tableKey = CdnConfigurationTab.PageRules;

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.pageRules' });
  const { t: tModals } = useTranslation('configuration', { keyPrefix: 'modals.pageRules' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.pageRules' });

  const pageRulesList = useConfigurationList(tableKey);
  const isPageRulesLoading = useConfigurationLoading(tableKey);
  const pageRulesPagination = useConfigurationPagination(tableKey);

  const pageRulesTableColumns = useMemo(getPageRulesTableColumns, []);
  const memorizedPriorityColumns = useMemo(getPageRulesPriorityTableColumns, [getPageRulesPriorityTableColumns]);

  const [openPriorityModal, setOpenPriorityModal] = useState<boolean>(false);

  return (
    <ConfigurationCard
      title={tTabs('pageRules')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isPageRulesLoading,
        createLabel: tButton('addNewPageRules'),
        setOpen: () => setOpenPriorityModal(true),
      }}
    >
      <PrioritizeModal
        title={tModals('prioritizePageRules')}
        columns={memorizedPriorityColumns}
        className="min-w-[10rem]"
        tableKey="pageRules"
        open={openPriorityModal}
        actionHandlers={actionHandlers}
        onClose={() => setOpenPriorityModal(false)}
      />
      <DraggableTable
        rows={pageRulesList}
        isDataLoading={isPageRulesLoading}
        columns={pageRulesTableColumns}
        isDraggable={false}
        actionHandlers={actionHandlers}
        pagination={pageRulesPagination}
        tableKey={tableKey}
      />
    </ConfigurationCard>
  );
};
