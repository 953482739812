import { format, isToday } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { AlertTriangle, CheckCircle, Info, Trash, XCircle } from 'lucide-react';

import { StatusType } from '@enums';
import { useSnack } from '@hooks';
import { useSnacks } from '@store';
import { useEffect } from 'react';
import { MultilineText } from './multiline';

const MAX_SNACKS = 3;

const CloseIcon = ({ size = 24, color = 'currentColor', strokeWidth = 2 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
};

const getColorMapping = (type) => {
  const mappings = {
    [StatusType.Info]: { bg: 'bg-blue-500', icon: Info },
    [StatusType.Success]: { bg: 'bg-green-500', icon: CheckCircle },
    [StatusType.Warning]: { bg: 'bg-yellow-500', icon: AlertTriangle },
    [StatusType.Error]: { bg: 'bg-red-500', icon: XCircle },
    [StatusType.Delete]: { bg: 'bg-red-500', icon: Trash },
    [StatusType.Inactive]: { bg: 'bg-red-500', icon: Trash },
  };
  return mappings[type] || mappings[StatusType.Info];
};

const Snack = ({ snack, index, onClose, count }) => {
  const { bg, icon: Icon } = getColorMapping(snack.type);
  const date = new Date();
  const formattedDate = isToday(date) ? `Today ${format(date, 'hh:mm a')}` : format(date, 'MM/dd/yyyy hh:mm a');

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      transition={{ type: 'spring', damping: 25, stiffness: 350 }}
      className={`${bg} mx-4 text-white rounded-lg shadow-lg overflow-hidden mb-4`}
    >
      <div className="flex items-start p-4 space-x-3">
        <Icon className="size-5 flex-shrink-0 mt-0.5" />
        <div className="flex-grow min-w-0">
          <MultilineText text={snack.message} />
          <p className="text-xs opacity-75 mt-1">{formattedDate}</p>
        </div>
        <button onClick={onClose} className="text-white/80 hover:text-white transition-colors flex-shrink-0">
          <CloseIcon size={15} strokeWidth={3} />
        </button>
      </div>
    </motion.div>
  );
};

const Snacks = () => {
  const snacks = useSnacks();
  const { hideSnack } = useSnack();
  const visibleSnacks = snacks.slice(0, MAX_SNACKS);

  useEffect(() => {
    const timeouts = snacks.map((snack, index) => setTimeout(() => hideSnack(index), snack.timeout));

    if (snacks.length > MAX_SNACKS) {
      hideSnack(0);
    }

    return () => timeouts.forEach(clearTimeout);
  }, [snacks, hideSnack]);

  return (
    <div className="fixed top-4 right-1 z-50 flex flex-col items-end">
      <div className="flex flex-col items-end">
        <AnimatePresence initial={false}>
          {visibleSnacks.map((snack, index) => (
            <Snack
              key={snack.id || index}
              snack={snack}
              index={index}
              onClose={() => hideSnack(index)}
              count={visibleSnacks.length}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Snacks;
