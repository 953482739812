import { breakpoints, Button, ConfirmationModal, Text, useMediaQuery } from '@/components';
import { StatusType, VariantType } from '@/enums';
import { useTranslation } from 'react-i18next';

export const SettingsButtons = ({
  activeTab,
  tabName,
  resetTitle,
  resetContent,
  openResetDialog,
  handleResetModal,
  handleSaveModal,
  tModal,
  formikRef,
  isLoading,
}: any) => {
  const { t: tConfigurationButton } = useTranslation('configuration', { keyPrefix: 'buttons' });
  const { t: tCommon } = useTranslation('common');

  const isAboveSm = useMediaQuery(`(min-width: ${breakpoints.sm}px)`);

  return (
    <div className="flex gap-4 flex-1 items-end sm:flex-none">
      <Button
        variant={VariantType.Outlined}
        fullWidth={!isAboveSm}
        loading={isLoading}
        className="rounded-md ring-theme-primary-main"
        onClick={handleResetModal}
      >
        {tConfigurationButton('resetSettings')}
      </Button>
      <Button fullWidth={!isAboveSm} onClick={handleSaveModal} loading={isLoading} disabled={false}>
        {tConfigurationButton('saveSettings')}
      </Button>

      {openResetDialog && (
        <ConfirmationModal
          title={resetTitle}
          content={
            <div className="flex flex-col gap-5 pt-2">
              <Text $level={4} className="text-sm">
                {resetContent}
              </Text>
              <div className="ring-1 ring-red-400 bg-red-50 px-2.5 py-1.5 rounded-md">
                <Text $level={6} color="text-red-400" className="text-us ">
                  {tModal('actionCantBeUndone')}
                </Text>
              </div>
            </div>
          }
          confirmButton={{ children: tCommon('button.confirm'), onClick: () => formikRef.current?.handleReset() }}
          open={openResetDialog}
          type={StatusType.Warning}
          onClose={handleResetModal}
        />
      )}
    </div>
  );
};
