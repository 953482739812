import { SnackState } from '@/types';
import { useAppDispatch } from '@hooks';
import { addSnack, clearSnack } from '@store';

export const useSnack = () => {
  const dispatch = useAppDispatch();

  const displaySnack = (snack: SnackState) => {
    dispatch(addSnack(snack));
  };

  const hideSnack = (id) => {
    dispatch(clearSnack(id));
  };

  return { displaySnack, hideSnack } as const;
};
