import classNames from 'classnames';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

import { Skeleton } from '@components';
import { Direction, InputMod, InputType, VariantType } from '@enums';
import { validateValueEqual } from '@utils';

import ErrorMessage from './errorMessage.tsx';
import { InputWithIcons } from './inputWithIcons.tsx';

export interface InputTextProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  multiline?: boolean;
  name: string;
  errorClassName?: string;
  isCorrect?: boolean;
  hints?: string;
  fontSize?: string;
  className?: string;
  adornment?: ReactNode;
  withoutRing?: boolean;
  adornmentWithoutM?: boolean;
  rounded?: string;
  fullWidth?: boolean;
  customizeColor?: boolean;
  transform?: (event: ChangeEvent<HTMLInputElement>) => ChangeEvent<HTMLInputElement>;
  smallFont?: boolean;
  variant?: VariantType;
  inputType?: InputType;
  clearFunction?: () => void;
  inputMod?: InputMod;
  withoutClear?: boolean;
  label?: string;
  autoComplete?: string;
  adornmentPosition?: Direction;
  loading?: boolean;
}

export const TextInput: React.FC<InputTextProps> = ({
  multiline = false,
  name,
  errorClassName = '',
  isCorrect,
  hints,
  fontSize,
  type = 'text',
  onBlur,
  transform,
  adornment,
  withoutRing,
  customizeColor = false,
  onPaste,
  disabled,
  autoComplete = 'off',
  smallFont = false,
  className = `py-1.5 ${type === 'number' ? 'pr-16' : 'pr-10 '}`,
  variant = VariantType.Normal,
  inputType,
  inputMod,
  adornmentWithoutM = false,
  fullWidth,
  clearFunction,
  maxLength,
  min,
  max,
  withoutClear,
  rounded = 'rounded-md',
  adornmentPosition = Direction.Left,
  value,
  loading = false,
  onChange,
  label,
  placeholder,
}: InputTextProps) => {
  const [field, meta, helpers] = useField(name);
  const isError = meta.error && meta.touched;
  const isCustom = validateValueEqual(variant, VariantType.Custom);
  const isFilled = validateValueEqual(inputMod, InputMod.Filled);
  const isPassword = validateValueEqual(type, 'password');
  const isNumber = validateValueEqual(type, 'number');
  const isAdornmentLeft = validateValueEqual(adornmentPosition, Direction.Left);
  const isAdornmenRight = validateValueEqual(adornmentPosition, Direction.Right);
  const isAdornmentOutside = validateValueEqual(adornmentPosition, Direction.Outside);

  const [showClearIcon, setShowClearIcon] = useState(false);
  const [inputPaddingRight, setInputPaddingRight] = useState<string | number>('10px');
  const [inputPaddingLeft, setInputPaddingLeft] = useState<string | number>('0px');
  const [iconPaddingRight, setIconPaddingRight] = useState<string | number>('12px');

  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<string>('password');

  const adornmentRef = useRef<HTMLDivElement>(null);
  const iconsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const updatePadding = () => {
      const adornmentWidth = adornmentRef.current?.offsetWidth ?? 0;
      const iconsContainerWidth = iconsContainerRef.current?.offsetWidth ?? 0;

      setInputPaddingRight(`${(isAdornmentLeft ? adornmentWidth : 0) + iconsContainerWidth + 3}px`);
      setIconPaddingRight(`${(isAdornmentLeft ? adornmentWidth : 0) + 10}px`);
      setInputPaddingLeft(`${!isAdornmentLeft ? adornmentWidth + 10 : 12}px`);

      isAdornmentLeft;
    };

    updatePadding();

    window.addEventListener('resize', updatePadding);
    return () => window.removeEventListener('resize', updatePadding);
  }, [adornment, showClearIcon, isError]);

  useEffect(() => {
    setShowClearIcon(!!field.value && userHasInteracted);
  }, [field.value, meta.touched, userHasInteracted]);

  const dynamicSkeletonClass = classNames('h-10', {
    'w-full': !adornment || fullWidth,
    [rounded]: rounded,
  });

  if (loading) return <Skeleton className={dynamicSkeletonClass} />;

  const handleClear = () => {
    helpers.setValue('');
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setUserHasInteracted(true);
    event.target.focus();
  };

  const handleHideIcon = () => {
    const isPassword = isHide === 'password';
    setIsHide(isPassword ? 'string' : 'password');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const transformedEvent: any = transform ? transform(event) : event;

    field.onChange(transformedEvent);
    if (onChange) {
      onChange(transformedEvent);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setUserHasInteracted(false);

    let trimmedValue = event.target.value?.trim?.() ?? event.target.value;

    if (isNumber && min !== undefined) {
      const numValue = Number(trimmedValue);
      if (isNaN(numValue) || numValue < Number(min)) {
        trimmedValue = min.toString();
      }
    }

    const newEvent = {
      ...event,
      target: { ...event.target, value: trimmedValue },
    };

    if (onBlur) {
      onBlur(newEvent);
    } else {
      field.onBlur({
        ...newEvent,
        target: {
          ...newEvent.target,
          name: event.target.name,
        },
      });
    }
  };

  return (
    <motion.div
      className={`flex flex-col h-fit space-y-3 ${fullWidth ? 'w-full' : ''}`}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-1 gap-3">
        <motion.div className="relative group flex flex-1 items-center">
          {adornment && isAdornmenRight && (
            <div ref={adornmentRef} className={`absolute left-0 h-full flex ${adornmentWithoutM ? '' : 'pl-3'}`}>
              {adornment}
            </div>
          )}
          <motion.input
            id={name}
            name={name}
            ref={inputRef}
            placeholder={placeholder}
            value={value ?? field.value ?? ''}
            autoComplete={autoComplete}
            required
            onFocus={handleFocus}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            aria-invalid={!!(meta.error && meta.touched)}
            aria-describedby={isError ? `${name}-error` : undefined}
            onPaste={onPaste}
            min={isNumber ? min : undefined}
            max={isNumber ? max : undefined}
            disabled={disabled}
            type={isPassword ? isHide : type}
            className={classNames('block border-0 placeholder-gray-400 hover:cursor-text', {
              'w-full': !adornment || fullWidth,
              'sm:leading-6': fontSize,
              'px-2.5 py-1.5': !isCustom && !isFilled,
              'px-3': !isCustom && isFilled,
              'text-us': smallFont,
              'text-sm': !smallFont,
              'ring-1 ring-theme-border-default  focus:ring-indigo-600': !withoutRing,
              'resize-y': multiline,
              'ring-theme-error-main bg-red-50 focus:ring-theme-error-main': isError,
              'bg-gray-50 bg-opacity-10': !isError && !customizeColor,
              'w-full h-10 px-3 text-sm peer outline-none border-0 rounded': isFilled,
              'pt-9 pb-4 placeholder-transparent': isFilled && field.value,
              'group-focus-within:pt-9 peer-valid:pt-9 peer-valid:pb-4 group-focus-within:pb-4 group-focus-within:placeholder-gray-400 placeholder-transparent':
                isFilled && (!field.value || field.value === ''),
              'bg-theme-primary-light': disabled,
              [rounded]: rounded,
              [className]: className,
            })}
            style={{ paddingRight: inputPaddingRight, paddingLeft: inputPaddingLeft }}
            animate={{
              scale: userHasInteracted && !adornment ? 1.02 : 1,
            }}
            transition={{ duration: 0.2 }}
          />
          <AnimatePresence>
            {isFilled && label && (
              <label
                htmlFor={name}
                className={`transform transition-all text-gray-400 absolute top-0 left-0 flex items-center translate-x-2 group-focus-within:translate-x-0 peer-valid:translate-x-0 group-focus-within:translate-y-0.5 peer-valid:translate-y-0.5 h-full group-focus-within:h-1/2 peer-valid:h-1/2 pl-2 group-focus-within:pl-3 peer-valid:pl-3 ${field.value ? 'text-smLabel font-medium' : 'text-xs group-focus-within:text-smLabel peer-valid:text-[0.58rem] group-focus-within:font-medium peer-valid:font-medium'}`}
              >
                {label}
              </label>
            )}
          </AnimatePresence>
          <InputWithIcons
            isError={isError}
            isCorrect={isCorrect}
            field={field}
            iconInputRef={iconsContainerRef}
            withoutClear={withoutClear}
            hints={hints}
            isNumber={isNumber}
            paddingRight={iconPaddingRight}
            clearFunction={clearFunction}
            isPassword={isPassword}
            isHide={isHide}
            handleHideIcon={handleHideIcon}
            handleClear={handleClear}
            showClearIcon={showClearIcon}
          />
          {adornment && isAdornmentLeft && (
            <div ref={adornmentRef} className={`absolute right-0 h-full flex ${adornmentWithoutM ? '' : 'pr-3'}`}>
              {adornment}
            </div>
          )}
        </motion.div>
        {adornment && isAdornmentOutside && (
          <div className={`h-full flex ${adornmentWithoutM ? '' : 'pr-2'}`}>{adornment}</div>
        )}
      </div>
      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
