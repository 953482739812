import classNames from 'classnames';

import { urlRegexp } from '@/regexp';
import { PaginationProps } from '@/types';
import { Text } from '@components/Text';
import { capitalize, validateValueEqual } from '@utils';
import { Column } from '../DraggableTable';

interface RichTableCellDefaultProps {
  multiLines?: boolean;
  data?: any;
  column: Column;
  rowId: number;
  tableKey: string;
  pagination?: PaginationProps;
}

export const DefaultTableCell = ({
  data,
  rowId,
  pagination,
  tableKey,
  column,
  multiLines = false,
}: RichTableCellDefaultProps) => {
  const isColumnId = validateValueEqual('id', column.value) && validateValueEqual('#', column.label);
  const isId = validateValueEqual('id', column.value) && !validateValueEqual('#', column.label);

  const startingId = pagination ? (pagination.page - 1) * pagination.page_size + 1 : 1;

  const dynamicDefaultTableCellClasses = classNames('text-sm font-normal select-text leading-6 ', {
    'pl-3': isId,
    'h-full flex flex-col whitespace-break-spaces break-words': multiLines,
    'truncate ellipsis-text': !multiLines,
  });

  if (!data && !isColumnId)
    return (
      <Text $level={4} color="text-black" className={dynamicDefaultTableCellClasses}>
        —
      </Text>
    );

  return isColumnId ? (
    <Text $level={4} color="text-black" className={`pl-3 pt-0.5 font-light leading-6 ${multiLines ? 'h-full' : ''}`}>
      {rowId + startingId}
    </Text>
  ) : data ? (
    typeof data === 'object' ? (
      data
    ) : (
      <Text $level={4} color="text-black" className={dynamicDefaultTableCellClasses}>
        {typeof data === 'string' && (urlRegexp ? data : capitalize(data))}
        {typeof data === 'number' && data}
      </Text>
    )
  ) : (
    <Text $level={4} color="text-black" className={dynamicDefaultTableCellClasses}>
      —
    </Text>
  );
};
