import { DialogPanel } from '@headlessui/react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useErrorFormInner } from '@/hooks/useErrorFormInner';
import { Button, DialogModal, ModalActions, ModalBody, ModalDescription, ModalTitle, Text } from '@components';
import { VariantType } from '@enums';

type ConfirmButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: any;
};

type FormProps = {
  title: ReactNode;
  open: boolean;
  onClose: () => void;
  confirmButton?: ConfirmButtonProps;
  submitError: any;
  isSubmitting?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  className?: string;
  description?: string;
  withoutPadding?: boolean;
};

export const FormModal = ({
  title,
  open,
  confirmButton,
  onClose,
  submitError,
  isSubmitting,
  description,
  size = 'md',
  withoutPadding,
  children,
  className = '',
}: PropsWithChildren<FormProps>) => {
  const { submitForm } = useFormikContext();

  const { t: tButton } = useTranslation('common', { keyPrefix: 'button' });

  const modalClass = classNames('w-full relative flex flex-col transform transition-all text-left', {
    [className]: className,
  });

  useErrorFormInner({ submitError, isSubmitting });

  return (
    <DialogModal open={open} onClose={onClose} size={size} withoutPadding={withoutPadding}>
      <DialogPanel className={modalClass}>
        {title && typeof title !== 'string' ? title : <ModalTitle className="font-medium">{title}</ModalTitle>}
        {!description && typeof description !== 'string' ? (
          description
        ) : (
          <ModalDescription className="text-gray-500 w-full">{description}</ModalDescription>
        )}
        <ModalBody className="max-w-full mt-8 sm:mt-11">
          {children}
          {confirmButton && (
            <ModalActions>
              <Button
                loading={isSubmitting}
                variant={VariantType.Outlined}
                fullWidth
                onClick={onClose}
                className="items-center ring-theme-primary-main"
              >
                <Text $level={5} color="text-theme-priamry-main" className="ellipsis-text">
                  {tButton('cancel')}
                </Text>
              </Button>
              <Button
                onClick={confirmButton?.onClick ?? submitForm}
                fullWidth
                loading={isSubmitting}
                className=""
                {...confirmButton}
              />
            </ModalActions>
          )}
        </ModalBody>
      </DialogPanel>
    </DialogModal>
  );
};
