import { CdnConfigurationDashboardGraph, CdnConfigurationTab, CdnDashboardFilter } from '@enums';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState, useAppSelector } from '@hooks';

export interface InitialProps {
  isLoading: boolean;
  isSubmitting: boolean;
  loadSuccess: boolean;
  sortSuccess: boolean;
  saveSuccess: boolean;
  list: any[];
  content: object;
  sortOrder: [];
  selected: [];
  autoReload: boolean;
  clear: boolean;
  filters: any[];
  lastUpdated: number;
  pagination: {
    first_page?: number;
    last_page?: number;
    next_page?: number;
    page?: number;
    total?: number;
    total_pages?: number;
    page_size?: number;
  };
  error: string | null;
}

interface ConfigurationState {
  [CdnConfigurationTab.Dashboard]: InitialProps;
  [CdnConfigurationTab.Domain]: InitialProps;
  [CdnConfigurationTab.Origin]: InitialProps;
  [CdnConfigurationTab.Route]: InitialProps;
  [CdnConfigurationTab.SSL]: InitialProps;
  [CdnConfigurationTab.PredefinedWAF]: InitialProps;
  [CdnConfigurationTab.CustomWAF]: InitialProps;
  [CdnConfigurationTab.WhitelistWAF]: InitialProps;
  [CdnConfigurationTab.PredefinedPageRules]: InitialProps;
  [CdnConfigurationTab.PageRules]: InitialProps;
  [CdnConfigurationTab.PredefinedCache]: InitialProps;
  [CdnConfigurationTab.CustomCache]: InitialProps;
  [CdnConfigurationTab.Configuration]: InitialProps;
  [CdnConfigurationTab.Security]: InitialProps;
  [CdnConfigurationTab.SSLLog]: InitialProps;
}

const originState: InitialProps = {
  isLoading: false,
  isSubmitting: false,
  saveSuccess: false,
  sortSuccess: false,
  loadSuccess: false,
  list: [],
  content: {},
  sortOrder: [],
  clear: true,
  autoReload: true,
  selected: [],
  filters: [],
  lastUpdated: 0,
  pagination: {
    first_page: 1,
    last_page: 1,
    next_page: 1,
    page: 1,
    total: 0,
    total_pages: 0,
    page_size: 10,
  },
  error: null,
};

export const configurationInitialState: ConfigurationState = {
  [CdnConfigurationTab.Dashboard]: {
    ...originState,
    filters: [
      {
        origin: CdnConfigurationDashboardGraph.TotalTraffic,
        name: CdnDashboardFilter.Time,
        value: '15m',
      },
      {
        origin: CdnConfigurationDashboardGraph.TotalTraffic,
        name: CdnDashboardFilter.Domain,
        value: 'all',
      },
      {
        origin: CdnConfigurationDashboardGraph.TrafficLog,
        name: CdnDashboardFilter.Time,
        value: '15m',
      },
      {
        origin: CdnConfigurationDashboardGraph.TrafficLog,
        name: CdnDashboardFilter.Domain,
        value: 'all',
      },
    ],
  },
  [CdnConfigurationTab.Domain]: { ...originState },
  [CdnConfigurationTab.Origin]: {
    ...originState,
    pagination: {
      ...originState.pagination,
      page_size: 5,
    },
  },
  [CdnConfigurationTab.Route]: {
    ...originState,
    pagination: {
      ...originState.pagination,
      page_size: 5,
    },
  },

  [CdnConfigurationTab.SSL]: { ...originState },
  [CdnConfigurationTab.SSLLog]: { ...originState },
  [CdnConfigurationTab.PredefinedWAF]: { ...originState },
  [CdnConfigurationTab.CustomWAF]: { ...originState },
  [CdnConfigurationTab.WhitelistWAF]: { ...originState },
  [CdnConfigurationTab.PageRules]: { ...originState },
  [CdnConfigurationTab.PredefinedCache]: { ...originState },
  [CdnConfigurationTab.CustomCache]: { ...originState },
  [CdnConfigurationTab.Configuration]: { ...originState },
  [CdnConfigurationTab.PredefinedPageRules]: { ...originState },
  [CdnConfigurationTab.Security]: { ...originState },
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: configurationInitialState,
  reducers: {
    verifyDomainName(
      state,
      { payload }: PayloadAction<{ tableKey: string; application_id?: string; content_id?: string | number }>,
    ) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = true;
        state[tableKey].loadSuccess = false;
      }
    },
    verifyDomainNameSuccess(state, { payload }: PayloadAction<{ tableKey: string }>) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].loadSuccess = true;
      }
    },
    verifyDomainNameError: (state, { payload }: PayloadAction<any>) => {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].loadSuccess = false;
        state[tableKey].error = payload;
      }
    },
    submitClearCache(
      state,
      { payload }: PayloadAction<{ tableKey: string; application_id?: string; content_id?: string | number }>,
    ) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = true;
        state[tableKey].loadSuccess = false;
      }
    },
    submitClearCacheSuccess(state, { payload }: PayloadAction<{ tableKey: string }>) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].loadSuccess = true;
      }
    },
    submitClearCacheError: (state, { payload }: PayloadAction<any>) => {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].error = payload;
        state[tableKey].loadSuccess = false;
      }
    },
    getConfigurationList(
      state,
      {
        payload,
      }: PayloadAction<{
        tableKey: string;
        sorting?: boolean;
        application_id?: string;
        sslId?: string | number;
        hasPagination?: boolean;
      }>,
    ) {
      const { tableKey, sorting } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = true;
        if (sorting) {
          state[tableKey].sortSuccess = true;
        } else {
          state[tableKey].loadSuccess = false;
        }
      }
    },
    getConfigurationListSuccess(state, { payload }: PayloadAction<any>) {
      const { tableKey, responseResult, paginationInfo } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].list = responseResult;
        state[tableKey].pagination = {
          ...state[tableKey].pagination,
          ...paginationInfo,
        };
        state[tableKey].loadSuccess = true;
      }
    },
    getSortingListSuccess(state, { payload }: PayloadAction<any>) {
      const { tableKey, responseResult } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].sortOrder = responseResult;
        state[tableKey].sorting = true;
        state[tableKey].sortSuccess = true;
      }
    },
    getConfigurationListError(state, { payload }: PayloadAction<any>) {
      const { tableKey, responseResult } = payload;
      if (state[tableKey]) {
        state[tableKey].error = responseResult;
        state[tableKey].isLoading = false;
        state[tableKey].loadSuccess = false;
      }
    },
    setConfigurationLoading(state, { payload }: PayloadAction<any>) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = true;
      }
    },
    submitConfigurationForm(
      state,
      {
        payload,
      }: PayloadAction<{
        tableKey: string;
        disabledToast?: boolean;
        returnResult?: boolean;
        ignoreResponse?: boolean;
        sorting?: boolean;
        formData: any;
        content_id?: string | number;
        hasPagination?: boolean;
      }>,
    ) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isSubmitting = true;
        state[tableKey].saveSuccess = false;
      }
    },
    submitConfigurationFormSuccess(state, { payload }: PayloadAction<{ tableKey: string; responseResult: any }>) {
      const { tableKey, responseResult } = payload;
      if (state[tableKey]) {
        state[tableKey].isSubmitting = false;
        state[tableKey].saveSuccess = true;
        state[tableKey].error = null;
        state[tableKey].content = responseResult;
      }
    },
    submitConfigurationFormError(state, { payload }: PayloadAction<{ tableKey: string; responseResult: any }>) {
      const { tableKey, responseResult } = payload;
      if (state[tableKey]) {
        state[tableKey].isSubmitting = false;
        state[tableKey].saveSuccess = false;
        state[tableKey].error = responseResult;
      }
    },

    deleteConfigurationData: (
      state,
      { payload }: PayloadAction<{ tableKey: string; content_id: string | number; application_id?: string }>,
    ) => {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = true;
      }
    },
    deleteConfigurationDataError: (state, { payload }: PayloadAction<any>) => {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].isLoading = false;
        state[tableKey].error = payload;
      }
    },
    resetConfigurationForm: (state, { payload }: PayloadAction<string>) => {
      if (state[payload]) {
        state[payload].isSubmitting = originState.isSubmitting;
        state[payload].saveSuccess = originState.saveSuccess;
        state[payload].error = originState.error;
      }
    },
    getCurrentContent(state, { payload }: PayloadAction<{ tableKey: string; rowData: any }>) {
      const { tableKey, rowData } = payload;
      if (state[tableKey.toLowerCase()]) {
        state[tableKey.toLowerCase()].content = rowData;
      }
    },
    getTableSorting(state, { payload }: PayloadAction<{ tableKey: string; rowData: any[] }>) {
      const { tableKey, rowData } = payload;
      if (state[tableKey]) {
        state[tableKey].sortOrder =
          rowData.map((item, index) => ({
            id: item.id,
            name: item.name,
            sort_order: index + 1,
            original_id: item.original_id,
          })) ?? [];
      }
    },
    resetConfiguration: (state) => {
      return configurationInitialState;
    },

    resetLoadSuccess(state, { payload }: PayloadAction<{ tableKey: string }>) {
      const { tableKey } = payload;
      if (state[tableKey]) {
        state[tableKey].loadSuccess = false;
      }
    },
    selectTableData: (state, { payload }: PayloadAction<{ tableKey: string; id: string | string[] }>) => {
      const { tableKey, id } = payload;
      if (Array.isArray(id)) {
        if (JSON.stringify(id) === JSON.stringify(state[tableKey].selected)) {
          state[tableKey].selected = [];
        } else {
          state[tableKey].selected = id;
        }
      } else if (typeof id === 'number') {
        if (!state[tableKey].selected) {
          state[tableKey].selected = [];
        }
        const index = state[tableKey].selected.findIndex((item) => item === id);
        if (index === -1) {
          state[tableKey].selected = [...state[tableKey].selected, id];
        } else {
          state[tableKey].selected = state[tableKey].selected.filter((item) => item !== id);
        }
      }
    },
    getDashboardData: (state, { payload }: PayloadAction<any>) => {
      state[CdnConfigurationTab.Dashboard].isLoading = true;
    },
    getDashboardDataSuccess: (state, { payload }: PayloadAction<any>) => {
      const { tableKey, graphTypeKey, responseResult, lastUpdated, paginationInfo } = payload;
      state[CdnConfigurationTab.Dashboard].isLoading = false;
      state[CdnConfigurationTab.Dashboard].lastUpdated = lastUpdated;

      const existingEntry = state[CdnConfigurationTab.Dashboard].list.find((entry) => entry.label === graphTypeKey);

      if (paginationInfo) {
        state[tableKey].pagination = {
          ...state[tableKey].pagination,
          ...paginationInfo,
        };
      }

      if (existingEntry) {
        // Compare existing data with new data
        const isSameData = JSON.stringify(existingEntry.value) === JSON.stringify(responseResult);
        if (!isSameData) {
          // Replace the existing data with the new data
          existingEntry.value = responseResult;
        }
      } else {
        // Add a new entry for graphTypeKey
        state[CdnConfigurationTab.Dashboard].list.push({
          label: graphTypeKey,
          value: responseResult,
        });
      }
    },
    getDashboardDataError: (state, { payload }: PayloadAction<any>) => {
      state[CdnConfigurationTab.Dashboard].isLoading = true;
    },
    getMultipleRequest: (state, { payload }: PayloadAction<any>) => {},
    updateAutoReload: (state, { payload }: PayloadAction<any>) => {
      const { tableKey, status, clear } = payload;
      state[tableKey].autoReload = status;
      state[tableKey].clear = clear;
    },
    updatePageChanges: (state, { payload }: PayloadAction<any>) => {
      const { tableKey, page } = payload;
      state[tableKey].pagination.page = page;
    },
    updateFilterRequest: (state, { payload }) => {
      const { updatedValue, name, tableKey } = payload;
      const filtersToUpdate = state[tableKey]?.filters || [];

      updatedValue.forEach(({ graphTypeKey, filterValue }) => {
        const filterIndex = filtersToUpdate.findIndex(
          (filter) => filter.origin === name && filter.name === graphTypeKey,
        );

        if (filterIndex !== -1) {
          filtersToUpdate[filterIndex].value = filterValue;
        }
      });
    },
  },
});

export const {
  resetLoadSuccess,
  resetConfiguration,
  resetConfigurationForm,
  getTableSorting,
  getCurrentContent,
  selectTableData,
  getConfigurationList,
  getConfigurationListSuccess,
  getSortingListSuccess,
  getConfigurationListError,
  submitConfigurationForm,
  submitConfigurationFormSuccess,
  submitConfigurationFormError,
  deleteConfigurationData,
  deleteConfigurationDataError,
  verifyDomainName,
  updateFilterRequest,
  verifyDomainNameSuccess,
  verifyDomainNameError,
  getDashboardData,
  getDashboardDataSuccess,
  setConfigurationLoading,
  getDashboardDataError,
  getMultipleRequest,
  updatePageChanges,
  updateAutoReload,
  submitClearCacheError,
  submitClearCacheSuccess,
  submitClearCache,
} = configurationSlice.actions;

export const useConfigurationTableData = (tableKey: string) => {
  const PrioritizeList = useAppSelector((state) => state.configuration[tableKey]?.sortOrder);

  return useMemo(() => {
    return PrioritizeList ?? [];
  }, [PrioritizeList]);
};

export const useCurrentData = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey]?.content || {});
export const useSelectedConfigurationData = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey]?.selected);
const configurationReducer = configurationSlice.reducer;
export default configurationReducer;
export const useConfigurationList = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].list);
export const useConfigurationLoading = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].isLoading);
export const useConfigurationLoadSuccess = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].loadSuccess);
export const useConfigurationSortSuccess = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].sortSuccess);
export const useConfigurationPagination = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].pagination);
export const useConfigurationSubmitting = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].isSubmitting);
export const useConfigurationSaveSuccess = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].saveSuccess);
export const useConfigurationDashboardData = () =>
  useAppSelector((state: RootState) => state.configuration[CdnConfigurationTab.Dashboard].list);
export const useConfigurationDashboardList = () =>
  useAppSelector((state: RootState) => state.configuration[CdnConfigurationTab.Dashboard].list);
export const useConfigurationError = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].error);
export const useConfigurationAutoReload = (tableKey: string) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].autoReload);
export const useConfigurationDashboardLastUpdated = ({ tableKey }: { tableKey: string }) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].lastUpdated);
export const useConfigurationFilterList = ({ tableKey }: any) =>
  useAppSelector((state: RootState) => state.configuration[tableKey].filters);
export const useConfigurationFilter = ({ tableKey, name, origin }: any) => {
  const filters = useAppSelector((state: RootState) => state.configuration[tableKey].filters);
  return (
    filters.find((filter) => filter.name === name && filter.origin === origin) ?? {
      label: name,
      origin: origin,
      value: null,
    }
  );
};
