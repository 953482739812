import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { PaginationProps } from '@/types';
import { ActionHandlers, Column, DraggableTableCell, EmptyObject } from '@components';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface DraggableRowProps<T extends EmptyObject<T>> {
  row: T;
  index: number;
  handleMoveRow: (dragIndex: number, hoverIndex: number) => void;
  actionHandlers: ActionHandlers<T>;
  isDraggable?: boolean;
  columns: Column[];
  tableKey: string;
  handleDragEnd: () => void;
  pagination?: PaginationProps;
}

export const DraggableRow = memo(
  <T extends EmptyObject<T>>({
    row,
    actionHandlers,
    index,
    handleMoveRow,
    isDraggable = false,
    columns,
    handleDragEnd,
    pagination,
    tableKey,
  }: DraggableRowProps<T>) => {
    const [, drop] = useDrop({
      accept: 'ROW',
      hover: (draggedItem: DragItem) => {
        if (draggedItem.index !== index) {
          handleMoveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
    const [{ isDragging }, ref] = useDrag({
      type: 'ROW',
      canDrag: isDraggable,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_item, monitor) => {
        if (monitor.didDrop()) {
          handleDragEnd();
        }
      },
    });

    return (
      <tr ref={(node) => ref(drop(node))} key={index} className={`${isDragging ? 'opacity-50' : ''} even:bg-gray-50`}>
        {columns.map((column, colId) => (
          <DraggableTableCell
            {...column}
            key={colId}
            column={column}
            colId={colId}
            rowId={index}
            row={row}
            isDraggable={isDraggable}
            actionHandlers={actionHandlers}
            tableKey={tableKey}
            pagination={pagination}
          />
        ))}
      </tr>
    );
  },
);
