import clone from 'lodash-es/clone';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import isObjectLike from 'lodash-es/isObjectLike';

export interface RemoveEmptyFieldsOptions {
  leaveEmptyArrays?: boolean;
  leaveEmptyStrings?: boolean;
  multilineFields?: string[];
}

export function clearData<T extends Record<string, any>>(
  data: T,
  options: RemoveEmptyFieldsOptions = {
    leaveEmptyArrays: true,
    leaveEmptyStrings: false,
    multilineFields: [],
  },
): T {
  if (data instanceof FormData) {
    return data;
  }

  const cleanData = clone(data) as any;

  for (const key in cleanData) {
    if (Object.hasOwn(cleanData, key)) {
      const value = cleanData[key];

      if (typeof value === 'string') {
        if (!options.multilineFields?.includes(key)) {
          cleanData[key] = value.replace(/\s+/g, ' ');
        }
        cleanData[key] = value.trim();
        if (!options.leaveEmptyStrings && cleanData[key] === '') {
          delete cleanData[key];
        }
      } else if (isNil(value)) {
        delete cleanData[key];
      } else if (
        (Array.isArray(value) && value.length === 0) ||
        (Array.isArray(value) && value.every((item) => item === '')) ||
        !value
      ) {
        if (!options.leaveEmptyArrays) {
          delete cleanData[key];
        }
      } else if (isObjectLike(value) && isNaN(Date.parse(value))) {
        if (!isEmpty(value)) {
          cleanData[key] = clearData(value, options);
        }
        if (isEmpty(cleanData[key])) {
          delete cleanData[key];
        }
      }
    }
  }

  return cleanData;
}
