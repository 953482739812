import i18n from 'i18next';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  deleteConfigurationData,
  deleteConfigurationDataError,
  getConfigurationList,
  getConfigurationListError,
  getConfigurationListSuccess,
  getDashboardData,
  getDashboardDataError,
  getDashboardDataSuccess,
  getMultipleRequest,
  getSortingListSuccess,
  setConfigurationLoading,
  submitClearCache,
  submitClearCacheError,
  submitClearCacheSuccess,
  submitConfigurationForm,
  submitConfigurationFormError,
  submitConfigurationFormSuccess,
  updatePageChanges,
  verifyDomainName,
  verifyDomainNameError,
  verifyDomainNameSuccess,
} from '@store';

import { ConfigurationDashboardConfig, ConfigurationFormConfig } from '@/constants';
import { CdnConfigurationDashboardGraph, CdnConfigurationTab, StatusType } from '@enums';
import { API, returnAPIUrl, validateValueEqual } from '@utils';

export function* getConfigurationListSaga({ payload }: any) {
  const { tableKey, application_id, hasPagination = false, sorting = false, sslId = null } = payload;
  yield put(setConfigurationLoading({ tableKey }));
  const { basePath, endPath, formatData, sortPath, mockResponse } = ConfigurationFormConfig(tableKey);

  const { pagination } = yield select((state) => state.configuration[tableKey]);

  let url = `${basePath}?application_id=${application_id}`;

  if (sorting && sortPath) {
    url = `${sortPath}?application_id=${application_id}`;
  }

  if (validateValueEqual(tableKey, CdnConfigurationTab.SSLLog) && sslId) {
    url = `${basePath}/${sslId}${endPath}`;
  }

  try {
    let responseData;
    if (validateValueEqual(tableKey, '-')) {
      responseData = {
        data: mockResponse,
        headers: { total: mockResponse.length, total_pages: 1, first_page: 1, last_page: 1, page: 1 },
      };
    } else {
      const params = {
        ...(hasPagination && {
          page: pagination.page,
          page_size: pagination.page_size,
        }),
      };

      responseData = yield call(API.get, url, {
        params,
      });
    }
    const { data, headers } = responseData;

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    let formattedData = data;

    if (sorting && Array.isArray(data)) {
      formattedData = data.map((item, index) => ({
        id: item.id,
        name: item.name,
        sort_order: index + 1,
        original_id: index + 1,
      }));

      yield put(
        getSortingListSuccess({
          tableKey,
          responseResult: formattedData,
          paginationInfo,
        }),
      );
    } else {
      formattedData = yield call(formatData, data);

      yield put(
        getConfigurationListSuccess({
          tableKey,
          responseResult: formattedData,
          paginationInfo,
        }),
      );
    }
  } catch (error) {
    yield put(getConfigurationListError({ tableKey, responseResult: error as Error }));
  }
}

export function* submitConfigurationFormSaga({ payload }: PayloadAction<any, string>) {
  const {
    tableKey,
    returnResult = false,
    sorting = false,
    disabledToast = false,
    ignoreResponse = false,
    formData,
    hasPagination = false,
    content_id,
  } = payload;
  const { baseName, basePath, sortPostPath, successMessage } = ConfigurationFormConfig(tableKey);

  if (!basePath || !successMessage) {
    console.error('Invalid table key provided:', tableKey);
    return;
  }

  const { url, submitToastCode } = returnAPIUrl(sorting, sortPostPath, content_id, basePath);

  try {
    const { data: responseData } = yield call(API.post, url, formData);
    if (responseData) {
      if (!disabledToast) {
        yield put(
          addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.${baseName}${submitToastCode}`) }),
        );
      }
      yield put(
        submitConfigurationFormSuccess({
          tableKey,
          responseResult: responseData,
        }),
      );
      if (returnResult) {
        yield put(
          getConfigurationListSuccess({
            tableKey,
            responseResult: responseData,
          }),
        );
      }
      if (sorting && formData?.application_id) {
        yield put(
          getConfigurationList({
            tableKey,
            application_id: formData?.application_id,
            hasPagination,
          }),
        );
      }
    } else if (ignoreResponse) {
      yield put(
        submitConfigurationFormSuccess({
          tableKey,
          responseResult: true,
        }),
      );
    } else {
      yield put(addSnack({ type: StatusType.Error, message: `No ${tableKey} data available from the API` }));
      yield put(
        submitConfigurationFormError({ tableKey, responseResult: `No ${tableKey} data available from the API` }),
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<{ data: any }>;
    if (axiosError.response) {
      const { data } = axiosError.response.data;
      yield put(submitConfigurationFormError({ tableKey, responseResult: data }));
    }
  }
}

export function* deleteConfigurationDataSaga({ payload }: PayloadAction<any>) {
  const { tableKey, content_id, application_id } = payload;
  const { basePath, successMessage } = ConfigurationFormConfig(tableKey);

  try {
    if (content_id) {
      yield call(API.delete, `${basePath}/${content_id}`);
    }
    if (application_id) {
      yield put(getConfigurationList({ tableKey, application_id, hasPagination: true }));
    }
    yield put(addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.deleteSuccessful`) }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(deleteConfigurationDataError({ tableKey, responseResult: errorDetails }));
  }
}

export function* submitClearCacheSaga({ payload }: PayloadAction<any>) {
  const { tableKey, application_id } = payload;
  const { clearPath, successMessage } = ConfigurationFormConfig(tableKey);
  const url = `${clearPath}?application_id=${application_id}`;
  try {
    yield call(API.post, url);
    yield put(submitClearCacheSuccess({ tableKey }));
    yield put(addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.clearCached`) }));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(submitClearCacheError({ tableKey, responseResult: errorDetails }));
  }
}

export function* verifyDomainNameSaga({ payload }: PayloadAction<any>) {
  const { tableKey, content_id, application_id } = payload;
  const { verifyPath, successMessage } = ConfigurationFormConfig(tableKey);

  try {
    if (content_id) {
      yield call(API.post, `${verifyPath}/${content_id}`);
    } else yield call(API.post, `${verifyPath}?application_id=${application_id}`);
    yield put(verifyDomainNameSuccess({ tableKey }));
    yield put(addSnack({ type: StatusType.Success, message: i18n.t(`${successMessage}.domainVerified`) }));

    if (application_id) {
      yield put(getConfigurationList({ tableKey, application_id, hasPagination: true }));
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(verifyDomainNameError({ tableKey, responseResult: errorDetails }));
  }
}

export function* getDashboardDataSaga({ payload }: any) {
  const { tableKey, origin: dashboardOrigin, graphTypeKey, application_id, hasPagination } = payload;

  const { basePath, mockResponse } = ConfigurationDashboardConfig(graphTypeKey);

  const { filters } = yield select((state) => state.configuration[tableKey]);
  const { pagination } = yield select((state) => state.configuration[tableKey]);

  try {
    let responseData: { data: any; headers: any };

    if (validateValueEqual(graphTypeKey, CdnConfigurationDashboardGraph.AttackLog)) {
      responseData = {
        data: mockResponse,
        headers: {
          first_page: 1,
          last_page: 1,
          next_page: 1,
          page: 1,
          total: 0,
          total_pages: 0,
          page_size: 10,
        },
      };
    } else if (validateValueEqual(graphTypeKey, CdnConfigurationDashboardGraph.TrafficLog)) {
      const params = {
        ...(hasPagination && {
          page: pagination.page,
          page_size: pagination.page_size,
        }),
      };

      responseData = yield call(API.get, basePath, {
        params: { application_id, ...params },
      });
    } else {
      const params = {
        ...(hasPagination && {
          page: pagination.page,
          page_size: pagination.page_size,
        }),
      };
      const filterParams = filters.reduce((acc, { name, origin, value }) => {
        if (value !== 'all' && dashboardOrigin === origin) {
          acc[name] = value;
        }
        return acc;
      }, {});

      responseData = yield call(API.get, basePath, {
        params: { application_id, ...params, ...filterParams },
      });
    }
    const { data, headers } = responseData;

    const paginationHeader = headers['x-pagination'] || headers['X-Pagination'];
    const paginationInfo = paginationHeader ? JSON.parse(paginationHeader) : null;

    const getCurrentUnixTime = () => {
      return Math.floor(Date.now() / 1000);
    };

    yield put(
      getDashboardDataSuccess({
        graphTypeKey,
        tableKey,
        responseResult: data,
        lastUpdated: getCurrentUnixTime(),
        paginationInfo,
      }),
    );
    if (
      graphTypeKey === CdnConfigurationDashboardGraph.TrafficLog ||
      graphTypeKey === CdnConfigurationDashboardGraph.ErrorLog
    ) {
      yield call(getDashboardFeature, {
        tableKey,
        graphTypeKey,
        origin: dashboardOrigin,
        application_id,
        hasPagination,
      });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorDetails = errorResponse?.response?.data ?? error;
    yield put(getDashboardDataError({ graphTypeKey, responseResult: errorDetails }));
  }
}

function* getDashboardFeature({ tableKey, graphTypeKey, origin, application_id, hasPagination }) {
  const { clear } = yield select((state) => state.configuration[tableKey]);
  if (!clear) {
    yield delay(10000);
    const { autoReload } = yield select((state) => state.configuration[tableKey]);
    if (!clear && autoReload) {
      yield put(getDashboardData({ tableKey, graphTypeKey, origin, application_id, hasPagination }));
    }
  }
}

export function* getMultipleRequestSaga({ payload }: any) {
  const { requests, tab, origin } = payload;
  if (!requests) return;

  const getSaga = tab === CdnConfigurationTab.Dashboard && origin ? getDashboardDataSaga : getConfigurationListSaga;

  const forks = requests
    .filter((request) => !request.shouldNotUpdate)
    .map((request) =>
      fork(getSaga, {
        payload: tab === CdnConfigurationTab.Dashboard ? { tableKey: tab, ...request, origin } : request,
      }),
    );

  if (forks.length) {
    yield all(forks);
  }
}

export function* updatePageChangesSaga({ payload }: any) {
  const { tableKey, application_id } = payload;
  yield put(getConfigurationList({ tableKey, application_id, hasPagination: true }));
}

export function* configurationSagaWatcher() {
  yield takeLatest(getConfigurationList.type, getConfigurationListSaga);
  yield takeLatest(submitConfigurationForm.type, submitConfigurationFormSaga);
  yield takeLatest(deleteConfigurationData.type, deleteConfigurationDataSaga);
  yield takeLatest(verifyDomainName.type, verifyDomainNameSaga);
  yield takeLatest(submitClearCache.type, submitClearCacheSaga);
  yield takeLatest(getDashboardData.type, getDashboardDataSaga);
  yield takeLatest(getMultipleRequest.type, getMultipleRequestSaga);
  yield takeLatest(updatePageChanges.type, updatePageChangesSaga);
}
