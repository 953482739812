import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { Chip, SearchInput, Skeleton, Text } from '@components';
import { InputMod, VariantType } from '@enums';
import { validateValueEqual } from '@utils';
import ErrorMessage from './TextInput/errorMessage';

interface Option {
  value: string;
  label?: string;
}

interface MultipleSelectInputProps {
  disabled?: boolean;
  withoutRing?: boolean;
  name: string;
  errorClassName?: string;
  options?: Option[];
  loading?: boolean;
  placeholder?: string;
  className?: string;
  inputMod?: InputMod;
  format?: (label: string) => string;
  customWidth?: string;
  fullWidth?: boolean;
  variant?: VariantType;
  smallFont?: boolean;
  withoutPadding?: boolean;
  label?: string;
  disabledSearch: boolean;
}

export const MultipleSelectInput: React.FC<MultipleSelectInputProps> = ({
  disabled = false,
  withoutRing = false,
  name,
  errorClassName = '',
  options = [],
  loading = false,
  placeholder = '',
  className = '',
  inputMod = InputMod.Default,
  format,
  customWidth,
  fullWidth = false,
  variant = VariantType.Normal,
  smallFont = false,
  disabledSearch,
  withoutPadding = false,
  label = '',
}) => {
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const isError = meta.error && meta.touched;

  const isCustom = validateValueEqual(VariantType.Custom, variant);
  const isFilled = validateValueEqual(InputMod.Filled, inputMod);

  const selectedValues = Array.isArray(field.value) ? field.value : [];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setTimeout(() => {
          const inputRect = inputRef.current?.getBoundingClientRect();
          const dropdownRect = dropdownRef.current?.getBoundingClientRect();
          if (inputRect && dropdownRect) {
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - inputRect.bottom;
            const spaceAbove = inputRect.top;
            const dropdownHeight = dropdownRect.height;

            if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
              setDropdownPosition('top');
            } else {
              setDropdownPosition('bottom');
            }
          }
        }, 0);
      }
    }
  };

  const handleOptionClick = (option: Option) => {
    const newSelected = selectedValues.some((item: Option) => item.value === option.value)
      ? selectedValues.filter((item: Option) => item.value !== option.value)
      : [...selectedValues, option];
    helpers.setValue(newSelected);
  };

  const handleDeleteClick = (optionToDelete: Option) => {
    helpers.setValue(selectedValues.filter((option: Option) => option.value !== optionToDelete.value));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      helpers.setValue(options);
    } else {
      helpers.setValue([]);
    }
  };

  const dynamicClasses = classNames(
    'block rounded-md border border-gray-300 shadow-sm placeholder-gray-400 bg-white transition-all duration-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
    {
      'px-2.5 py-1.5': !isCustom && !withoutPadding,
      'text-us': smallFont,
      'text-sm px-3.5 py-2': !smallFont && !withoutPadding,
      'ring-red-500': isError,
      'bg-gray-100 hover:cursor-not-allowed': disabled,
      'hover:cursor-pointer text-black': !disabled,
      'text-gray-400': disabled,
      'w-full': fullWidth,
      'w-full px-3 text-sm peer outline-none rounded': isFilled,
      'pt-6 pb-2': isFilled && selectedValues.length > 0,
      'group-focus-within:pt-10 peer-valid:pt-10 peer-valid:pb-5 group-focus-within:pb-5':
        isFilled && selectedValues.length === 0,
      [className]: className,
    },
  );

  const dynamicSkeletonClass = classNames('h-10 rounded-md', {
    'w-full': fullWidth,
  });

  const filteredOptions = options.filter((option) =>
    (option.label || option.value).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (loading) return <Skeleton className={dynamicSkeletonClass} />;

  return (
    <div className={`flex flex-col h-fit space-y-3 ${fullWidth ? 'w-full' : ''} ${customWidth ?? ''}`}>
      <div className="relative group" ref={inputRef}>
        <div
          className={`${dynamicClasses} min-h-[38px] flex flex-wrap items-center gap-2 pr-8 py-2 ${isOpen ? 'ring-2 ring-indigo-600' : ''}`}
          onClick={handleToggle}
        >
          {selectedValues.length > 0 ? (
            selectedValues.map((item: Option) => (
              <Chip
                smallFont
                chipVariant={VariantType.Outlined}
                withoutPadding
                className="px-2 py-1 w-fit"
                onChipClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(item);
                }}
                label={format ? format(item.label || item.value) : item.label || item.value}
                adornment={<XMarkIcon className="w-4 h-4 self-center text-blue-400" />}
              />
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
          <ChevronUpDownIcon className="w-5 h-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        {isFilled && label && (
          <label
            htmlFor={name}
            className={`transform transition-all text-gray-400 absolute top-0 left-0 flex items-center 
            translate-x-2 group-focus-within:translate-x-0 peer-valid:translate-x-0
            group-focus-within:translate-y-0.5 peer-valid:translate-y-0.5
            h-full group-focus-within:h-1/2 peer-valid:h-1/2 peer-disabled:h-1/2 peer-disabled:translate-y-0.5
            pl-2 disabled:pl-0 group-focus-within:pl-3 peer-valid:pl-3 peer-disabled:pl-1
            ${selectedValues.length > 0 ? 'text-smLabel font-medium' : 'text-xs group-focus-within:text-smLabel peer-valid:text-[0.58rem] group-focus-within:font-medium peer-valid:font-medium'}`}
          >
            {label}
          </label>
        )}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={dropdownRef}
              initial={{ opacity: 0, y: dropdownPosition === 'bottom' ? -10 : 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: dropdownPosition === 'bottom' ? -10 : 10 }}
              transition={{ duration: 0.2 }}
              className={`absolute w-full z-20 ${
                dropdownPosition === 'bottom' ? 'top-full' : 'bottom-full'
              } mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
            >
              {!disabledSearch && (
                <div className="sticky top-0 bg-white px-4 py-2 border-b border-gray-200">
                  <SearchInput
                    name="search"
                    className="bg-white bg-opacity-50 focus:bg-opacity-100 focus-within:bg-opacity-100"
                    placeholder={'Search'}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    clearFunction={() => setSearchTerm('')}
                  />
                </div>
              )}
              <div className="px-4 py-2 cursor-pointer transition-colors duration-200 flex items-center hover:bg-gray-100">
                <input
                  type="checkbox"
                  className="form-checkbox size-4 text-indigo-600 rounded border-gray-300 focus:ring-0 mr-3"
                  checked={selectedValues.length === options.length}
                  onChange={handleSelectAll}
                />
                <Text $level={6} className="flex-grow font-medium text-gray-700">
                  {selectedValues.length === options.length ? 'Unselect all' : 'Select All'}
                </Text>
              </div>
              {filteredOptions.map((option) => {
                const isSelected = selectedValues.some((item: Option) => item.value === option.value);
                return (
                  <div
                    key={option.value}
                    className={`px-4 py-2 cursor-pointer transition-colors duration-200 flex items-center ${
                      isSelected ? 'bg-indigo-50 text-indigo-900' : 'text-gray-900 hover:bg-gray-100'
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox size-4 text-indigo-600 rounded border-gray-300 focus:ring-0 mr-3"
                      checked={isSelected}
                      readOnly
                    />
                    <Text $level={6} className="flex-grow">
                      {format ? format(option.label || option.value) : option.label || option.value}
                    </Text>
                    {isSelected && <CheckIcon className="w-5 h-5 text-indigo-600" />}
                  </div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {isError && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ErrorMessage isError={isError} errorClassName={errorClassName} meta={meta} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
