import { StorageUtility, createStorageUtility } from './utils/storage/storage';

type InitType = {
  timeZone: string;
  displayMode: string;
  language: any;
  userEmail?: string;
  userId?: string | null;
  firstName?: string;
  lastName?: string;
  workspace?: number;
};

export const initData = createStorageUtility<InitType>('init');
export const accessToken = createStorageUtility<string>('access_token');
export const refreshToken = createStorageUtility<string>('refresh_token');
export const workSpaceId = createStorageUtility<string>('workspace_id');

export const updateDataInStorage = (storageUtility: StorageUtility<any>, keysToUpdate: string[], newValues: any) => {
  const currentData = storageUtility.getItem();
  const updatedData = { ...currentData, ...newValues };

  for (const key of keysToUpdate) {
    if (Reflect.has(newValues, key)) {
      updatedData[key] = newValues[key];
    }
  }

  storageUtility.setItem(updatedData);
  return updatedData;
};

export function clearAllStorageData(): void {
  [initData, accessToken, refreshToken, workSpaceId].forEach((utility) => utility.clearItem());
}

export function getAllStorageData() {
  try {
    return {
      current_init_data: initData.getItem(),
      current_access_token: accessToken.getItem(),
      current_refresh_token: refreshToken.getItem(),
      current_workspace_id: workSpaceId.getItem(),
    };
  } catch (error) {
    console.error('Error retrieving storage items:', error);
    throw error;
  }
}
