import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs, Responsive, Text } from '@components';
import { SideNavigation } from '@pages/Cdn';

import { ApplicationSelectBox } from './subComponents/applicationSelectBox';

export const ConfigurationLayout = ({ children }: PropsWithChildren<object>) => {
  const { t: tConfiguration } = useTranslation('configuration');

  const { pathname } = useLocation();

  const getLastPathnameSegment = () => {
    const pathSegments = pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  };

  return (
    <div className="max-w-full w-full space-y-8">
      <Breadcrumbs />
      <div className="grid grid-cols-1 lg:grid-cols-9 lg:flex-row gap-5 sm:gap-8 lg:gap-9">
        <div className="col-span-1 lg:col-span-2">
          <SideNavigation />
        </div>
        <div className="col-span-1 lg:col-span-7 space-y-7">
          <div className="flex justify-between items-center">
            <div className="flex flex-1 flex-col gap-3">
              <Responsive showAbove="lg">
                <Text $level={3} color="text-black" className="font-medium">
                  {tConfiguration(`${getLastPathnameSegment()}.pageTitle`)}
                </Text>
              </Responsive>
              <Text $level={5} color="text-gray-400" className="font-light leading-4">
                {tConfiguration(`${getLastPathnameSegment()}.pageSummary`)}
              </Text>
            </div>
            <Responsive showAbove="lg">
              <ApplicationSelectBox />
            </Responsive>
          </div>
          {children}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
