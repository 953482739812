import type { RootState } from '@hooks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '@hooks/storeHooks';
import { SystemSliceState } from '../../types';

const initialState: SystemSliceState = {
  networkConnection: {
    isOnline: true,
    isOffline: false,
    backOnline: false,
    backOffline: false,
  },
  apiConnection: {
    isConnected: true,
    redirectPath: '/',
  },
  timeZone: '',
  page: {
    isActive: true,
    isCollapsed: false,
  },
  error: null,

  loading: false,
};

export interface SystemUpdatePageFocusPayload {
  isActive: boolean;
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    systemUpdateNetworkConnection: (state, { payload }: PayloadAction<any>) => {
      state.networkConnection = payload;
    },
    systemUpdatePageFocus: (state, { payload }: PayloadAction<SystemUpdatePageFocusPayload>) => {
      state.page.isActive = payload.isActive;
    },
    setSidebarCollapsible: (state) => {
      state.page.isCollapsed = !state.page.isCollapsed;
    },
    setTimezone: (state, { payload }: PayloadAction<string>) => {
      state.timeZone = payload;
    },
    updateApiConnectionStatus: (state, { payload }: PayloadAction<any>) => {
      const { status } = payload;
      if (state.apiConnection.isConnected !== status) {
        state.apiConnection.isConnected = status;
      }
    },
    updateRedirectPath: (state, { payload }: PayloadAction<any>) => {
      const { previousPath } = payload;
      state.apiConnection.redirectPath = previousPath;
    },
    systemDispatch: (state, action: PayloadAction<any>) => {},
    updateToken: (state, { payload }: PayloadAction<any>) => {},
    updateTokenSuccess: (state, { payload }: PayloadAction<boolean>) => {},
    fetchTokenContinuously: () => {},
    fetchTokenContinuouslySuccess: () => {},
    fetchTokenContinuouslyError: () => {},
    fetchNotificationContinuously: () => {},
    systemError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
  },
});

export const {
  systemUpdateNetworkConnection,
  systemUpdatePageFocus,
  fetchNotificationContinuously,
  setSidebarCollapsible,
  setTimezone,
  systemDispatch,
  updateToken,
  updateTokenSuccess,
  fetchTokenContinuously,
  fetchTokenContinuouslySuccess,
  fetchTokenContinuouslyError,
  updateApiConnectionStatus,
  updateRedirectPath,
  systemError,
} = systemSlice.actions;

export const useSystemNetworkConnection = () => useAppSelector((state: RootState) => state.system);
export const useSystemPageActivityStatus = () => useAppSelector((state: RootState) => state.system.page.isActive);
export const useSystemIsOnline = () => useAppSelector((state: RootState) => state.system.networkConnection.isOnline);
export const useSystemIsBackOnline = () =>
  useAppSelector((state: RootState) => state.system.networkConnection.backOnline);
export const useTimezone = () => useAppSelector((state: RootState) => state.system.timeZone);
export const useSidebarCollapsed = () => useAppSelector((state: RootState) => state.system.page.isCollapsed);
export const useSystemError = () => useAppSelector((state: RootState) => state.system.error);
export const useApiStatus = () => useAppSelector((state: RootState) => state.system.apiConnection.isConnected ?? false);
export const useRedirectPath = () =>
  useAppSelector((state: RootState) => state.system.apiConnection.redirectPath ?? '/');

const systemReducer = systemSlice.reducer;
export default systemReducer;
