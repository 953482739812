import { GlobeAsiaAustraliaIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getCdnDomainListColumns } from '@/constants';
import { CdnConfigurationTab, StatusType, VariantType } from '@/enums';
import { useAppDispatch } from '@/hooks';
import { Cards, Chip, ConfigurationActionButton, Direction, DraggableTable, InformationModal, Text } from '@components';

import {
  addSnack,
  updatePageChanges,
  useCdnTableData,
  useConfigurationList,
  useConfigurationLoading,
  useConfigurationPagination,
} from '@store';
import { UnverifiedDomainsAnnouncement } from './domainAnnouncement';

export const DomainForm = ({ actionHandlers }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const { t: tTab } = useTranslation('configuration', { keyPrefix: 'tabs.domain' });
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.domain' });
  const { t: tToast } = useTranslation('configuration', { keyPrefix: 'toasts.domain' });
  const { t: tDescription } = useTranslation('description', { keyPrefix: 'configuration.domain.cName' });
  const { t: tTables } = useTranslation('description', { keyPrefix: 'configuration.tables' });

  const tableKey = CdnConfigurationTab.Domain;
  const currentApplicationData = useCdnTableData();

  const domainList = useConfigurationList(tableKey);
  const isDomainLoading = useConfigurationLoading(tableKey);
  const domainPagination = useConfigurationPagination(tableKey);

  const cNameData = currentApplicationData?.cname;

  const cdnDomainTableColumns = useMemo(
    () => getCdnDomainListColumns({ cname: cNameData, actionHandlers: actionHandlers }),
    [tTables, cNameData],
  );

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleDialog = async () => {
    if (!cNameData) {
      setOpenDialog(!openDialog);
    } else {
      try {
        await navigator.clipboard.writeText(cNameData);
        dispatch(addSnack({ type: StatusType.Success, message: tToast('copySuccessfully', { cname: cNameData }) }));
      } catch (err) {
        console.warn('Unable to copy cname');
      }
    }
  };

  const handlePageChange = (page) => {
    dispatch(updatePageChanges({ tableKey, page, application_id }));
  };
  return (
    <Cards
      rounded="rounded-2xl"
      withoutPadding
      className="flex flex-1 flex-col bg-white bg-opacity-90 px-8 py-7 space-y-6 lg:space-y-8 overflow-hidden"
    >
      <div className="flex flex-col">
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between">
          <div className="flex sm:flex-col gap-5 items-center sm:items-start">
            <Text color="text-black" $level={2} className=" font-medium leading-6">
              {currentApplicationData?.domain ?? tTab('domainList')}
            </Text>

            <div className="flex space-x-3 font-medium text-us">
              <Chip
                smallFont
                chipType={StatusType.Success}
                label={cNameData ?? tDescription('cNameNotSet')}
                chipVariant={VariantType.Outlined}
                onChipClick={handleDialog}
                direction={Direction.Left}
                adornment={
                  cNameData ? (
                    <GlobeAsiaAustraliaIcon className="animate-spin-gradual w-5 h-5" />
                  ) : (
                    <ExclamationTriangleIcon className="w-5 h-5" />
                  )
                }
              />
            </div>
          </div>
          <div className="flex items-center gap-x-4  text-theme-neutral-text">
            <ConfigurationActionButton
              loading={isDomainLoading}
              actionHandlers={actionHandlers}
              createLabel={tButton('addNewDomain')}
            />
          </div>
        </div>
      </div>
      <InformationModal
        title={tDescription(cNameData ? 'cNameSet' : 'cNameNotSet')}
        topTitle
        content={
          <article className="text-xs leading-5 text-justify pt-1">
            {tDescription(cNameData ? 'withCnameDescription' : 'withoutCnameDescription')}
          </article>
        }
        onClose={handleDialog}
        open={openDialog}
      />
      <UnverifiedDomainsAnnouncement
        actionHandlers={actionHandlers}
        cname={cNameData}
        currentApplicationData={domainList}
      />
      <DraggableTable
        rows={domainList}
        columns={cdnDomainTableColumns}
        isDataLoading={isDomainLoading}
        pagination={domainPagination}
        tableKey={tableKey}
        onChangePage={handlePageChange}
        isDraggable={false}
        actionHandlers={actionHandlers}
      />
    </Cards>
  );
};
