import React from 'react';
import styled, { css, keyframes } from 'styled-components';

// Keyframe for the zoom animation
const zoom = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Container styled component
const LineContainer = styled.div<{
  size: string;
  color: string;
  speed: string;
  stroke: number;
  $bgOpacity: number;
  $loading: boolean; // Use transient prop with $ prefix
}>`
  --uib-size: ${(props) => props.size}; /* Use size directly */
  --uib-color: ${(props) => props.color};
  --uib-speed: ${(props) => props.speed};
  --uib-stroke: ${(props) => `${props.stroke / 16}rem`}; /* Convert px to rem */
  --uib-bg-opacity: ${(props) => props.$bgOpacity};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-stroke);
  width: var(--uib-size);
  border-radius: calc(var(--uib-stroke) / 2);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  background-color: rgba(137, 191, 248, 0.2);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: var(--uib-bg-opacity);
    transition: background-color 0.3s ease;
  }

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: calc(var(--uib-stroke) / 2);
    transition: background-color 0.3s ease;
    ${(props) =>
      props.$loading
        ? css`
            background-color: var(--uib-color);
            animation: ${zoom} var(--uib-speed) ease-in-out infinite;
          `
        : css`
            background-color: transparent;
            animation: none;
            transform: translateX(0); /* Keep the bar static if not loading */
          `}
  }
`;

interface LineLoaderProps {
  size?: number | string;
  color?: string;
  speed?: string;
  stroke?: number;
  bgOpacity?: number;
  className?: string;
  loading: boolean;
}

export const LineLoader: React.FC<LineLoaderProps> = ({
  size = 60,
  color = 'rgb(59 130 246 / 0.5)',
  speed = '1.4s',
  stroke = 5,
  bgOpacity = 0.1,
  className,
  loading = false,
}) => {
  const sizeValue = typeof size === 'number' ? `${size / 16}rem` : size;

  return (
    <LineContainer
      size={sizeValue}
      color={color}
      speed={speed}
      stroke={stroke}
      $bgOpacity={bgOpacity}
      className={className}
      $loading={loading}
    />
  );
};
