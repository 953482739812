import i18n from 'i18next';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { accessToken, getAllStorageData, refreshToken } from '@/storage';
import { API, setHeaders } from '@/utils';
import { Headers, StatusType } from '@enums';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addSnack,
  appInitError,
  fetchTokenContinuously,
  fetchTokenContinuouslyError,
  fetchTokenContinuouslySuccess,
  logOut,
  setAppLoading,
  systemError,
  systemUpdateNetworkConnection,
  systemUpdatePageFocus,
  updateApiConnectionStatus,
  updateToken,
  updateTokenSuccess,
} from '@store/slices';
import jwt_decode from 'jwt-decode';

const TOKEN_REFRESH_THRESHOLD = 60; //1 minute
const REFRESH_BUFFER = 10; // 10 seconds
const MIN_VALID_DURATION = 30; // 30 seconds

function* fetchTokenContinuouslySuccessSaga() {}

function isTokenExpired(token: string): boolean {
  try {
    const decoded: any = jwt_decode(token);
    return decoded.exp <= Math.floor(Date.now() / 1000);
  } catch (e) {
    return true;
  }
}

function* calculateWaitTime() {
  const accessToken = yield call(getAccessToken);
  if (!accessToken) return MIN_VALID_DURATION;

  const decoded = jwt_decode<{ exp: number }>(accessToken);
  const currentTime = Math.floor(Date.now() / 1000);
  const timeUntilExpiration = decoded.exp - currentTime;
  return Math.max(timeUntilExpiration - REFRESH_BUFFER, MIN_VALID_DURATION);
}

function getAccessToken() {
  return localStorage.getItem('access_token');
}

function* updateNetworkConnectionSaga() {
  const isBackOnline = yield select((state) => state.system.networkConnection.backOnline);
  try {
    yield put(
      addSnack({
        type: isBackOnline ? StatusType.Success : StatusType.Error,
        message: i18n.t(isBackOnline ? 'common:connection.restored' : 'common:connection.disconnected'),
        clear: isBackOnline,
      }),
    );
    if (isBackOnline) {
      yield put(systemUpdatePageFocus({ isActive: true }));
    }
  } catch (error) {
    yield put(systemError(error instanceof Error ? error : new Error('Unknown error')));
  }
}

function* checkTokenAfterInactivitySaga({ payload: { isActive } }: PayloadAction<{ isActive: boolean }>) {
  if (isActive) {
    yield put(setAppLoading(true));
    try {
      const access_token = yield call(accessToken.getItem);
      if (access_token && !isTokenExpired(access_token)) {
        yield call(updateTokenIfNeeded);
      } else {
        yield put(logOut());
      }
    } catch (e) {
      yield put(logOut());
    } finally {
      yield put(setAppLoading(false));
    }
  }
}

function* updateTokenIfNeeded() {
  try {
    const accessToken = yield call(getAccessToken);
    if (!accessToken) throw new Error('No access token found');

    const decoded = jwt_decode<{ exp: number }>(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeRemaining = decoded.exp - currentTime;

    if (timeRemaining <= TOKEN_REFRESH_THRESHOLD + REFRESH_BUFFER) {
      yield put(updateToken({ toast: false }));
      const { success } = yield race({
        success: take(updateTokenSuccess.type),
        failure: take(fetchTokenContinuouslyError.type),
      });

      if (success) {
        const newToken = yield call(getAccessToken);
        const newDecoded = jwt_decode<{ exp: number }>(newToken);
        const newTimeRemaining = newDecoded.exp - Math.floor(Date.now() / 1000);

        return newTimeRemaining >= MIN_VALID_DURATION ? 'refreshed' : 'failed';
      } else {
        return 'failed';
      }
    } else if (timeRemaining <= MIN_VALID_DURATION) {
      return 'failed';
    } else {
      return 'valid';
    }
  } catch (error) {
    return 'failed';
  }
}

function* fetchTokenContinuouslySaga() {
  while (true) {
    try {
      const result = yield call(updateTokenIfNeeded);

      if (result === 'failed') {
        yield put(fetchTokenContinuouslyError());
        yield put(appInitError('Error refreshing token'));
        return;
      }
      yield put(fetchTokenContinuouslySuccess());

      const waitTime = yield call(calculateWaitTime);

      const { logout } = yield race({
        wait: delay(waitTime * 1000),
        logout: take(logOut.type),
      });

      if (logout) return;
    } catch (error) {
      yield put(logOut());
      return;
    }
  }
}

function* updateTokenSaga({ payload }: PayloadAction<{ toast?: boolean }>) {
  try {
    const { current_refresh_token } = yield call(getAllStorageData);
    if (!current_refresh_token) throw new Error('Invalid refresh token');

    if (isTokenExpired(current_refresh_token)) {
      yield put(logOut());
      return;
    }

    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${current_refresh_token}` });
    const { data } = yield call(API.post, '/user/refresh');
    const { access_token, refresh_token } = data;
    if (!access_token || !refresh_token) throw new Error('Invalid token response');

    yield call(accessToken.setItem, access_token);
    yield call(refreshToken.setItem, refresh_token);
    yield call(setHeaders, { [Headers.Authorization]: `Bearer ${access_token}` });

    yield put(updateTokenSuccess(payload?.toast ?? false));
  } catch (e) {
    yield put(fetchTokenContinuouslyError());
    yield put(logOut());
  }
}

function* updateTokenSuccessSaga({ payload }: PayloadAction<boolean>) {
  if (payload) {
    yield put(addSnack({ type: StatusType.Success, message: 'Your login session has been refreshed' }));
  }
  yield put(updateApiConnectionStatus({ status: true }));
}

export function* systemSagaWatcher() {
  yield takeLatest(systemUpdatePageFocus.type, checkTokenAfterInactivitySaga);
  yield takeLatest(systemUpdateNetworkConnection.type, updateNetworkConnectionSaga);

  yield takeLatest(updateToken.type, updateTokenSaga);
  yield takeLatest(updateToken.type, updateTokenSuccessSaga);
  yield takeLatest(fetchTokenContinuously.type, fetchTokenContinuouslySaga);
  yield takeLatest(fetchTokenContinuouslySuccess.type, fetchTokenContinuouslySuccessSaga);
}
