import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditTableRoute } from '../../enums/globals/Utils';

import { ActionHandlers } from '@/components';
import { TableActionHandlerType, TableActionType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getCurrentContent,
  resetConfigurationForm,
  selectCurrentCdnData,
  selectTableData,
  submitConfigurationForm,
  useConfigurationTableData,
} from '@store';

export interface UseActionHandlersParams<T> {
  setData: Dispatch<SetStateAction<T | undefined>>;
  setEditOpen?: Dispatch<SetStateAction<boolean>>;
  setEdit?: Dispatch<SetStateAction<boolean>>;
  setViewOpen?: Dispatch<SetStateAction<boolean>>;
  setView?: Dispatch<SetStateAction<boolean>>;
  setVerifyOpen?: Dispatch<SetStateAction<boolean>>;
  setDeleteOpen?: Dispatch<SetStateAction<boolean>>;
  tableKey: string;
  routePath?: string;
  activeHandlers: TableActionType[];
  disabledHandlers?: TableActionHandlerType[];
  editTableRoute?: EditTableRoute;
}

export function useActionHandlers<T>(params: UseActionHandlersParams<T>): ActionHandlers<T> {
  const {
    setData,
    setEdit,
    setEditOpen,
    setViewOpen,
    setVerifyOpen,
    setDeleteOpen,
    tableKey,
    activeHandlers,
    disabledHandlers,
    routePath,
    editTableRoute = EditTableRoute.Configuration,
  } = params;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableData = useConfigurationTableData(tableKey);

  const getDispatchTable = useCallback(
    (rowData: T) => {
      switch (editTableRoute) {
        case EditTableRoute.Cdn:
          if (!routePath) return;
          return dispatch(selectCurrentCdnData({ rowData }));
        case EditTableRoute.Configuration:
          return dispatch(getCurrentContent({ tableKey, rowData }));

        default:
          return null;
      }
    },
    [dispatch, editTableRoute, routePath, tableKey],
  );

  const handleEdit = useCallback(
    (rowData: T) => {
      if (!activeHandlers.includes(TableActionType.Edit)) return;
      if (routePath) {
        const targetRoute = editTableRoute === EditTableRoute.Cdn ? `${rowData}/${routePath}` : routePath;
        navigate(targetRoute);
      } else {
        setData(rowData);
      }

      getDispatchTable(rowData);
      dispatch(resetConfigurationForm(tableKey));
      setEdit?.(true);
      setEditOpen?.(true);
    },
    [
      activeHandlers,
      dispatch,
      editTableRoute,
      getDispatchTable,
      navigate,
      routePath,
      setData,
      setEdit,
      setEditOpen,
      tableKey,
    ],
  );

  const handleDelete = useCallback(
    (rowData: T) => {
      if (!activeHandlers.includes(TableActionType.Delete) || !setDeleteOpen) return;
      setData(rowData);
      setDeleteOpen(true);
    },
    [activeHandlers, setData, setDeleteOpen],
  );

  const handleCreate = useCallback(() => {
    if (!activeHandlers.includes(TableActionType.Create)) return;
    setData(undefined as T);
    dispatch(resetConfigurationForm(tableKey));
    routePath && navigate(routePath);
    setEdit?.(false);
    setEditOpen?.(true);
  }, [activeHandlers, setData, dispatch, tableKey, routePath, navigate, setEdit, setEditOpen]);

  const handleVerification = useCallback(
    (rowData?: any) => {
      if (!activeHandlers.includes(TableActionType.Verification) || !setVerifyOpen) return;
      setData(rowData?.target?.value ?? rowData);
      setVerifyOpen(true);
    },
    [activeHandlers, setData, setVerifyOpen],
  );

  const handleView = useCallback(
    (rowData: T) => {
      if (!activeHandlers.includes(TableActionType.View)) return;
      dispatch(getCurrentContent({ tableKey, rowData }));
      setData(rowData);
      routePath ? navigate(routePath) : setData(rowData);
      setViewOpen?.(true);
    },
    [activeHandlers, dispatch, tableKey, setData, routePath, navigate, setViewOpen],
  );

  const handlePriority = useCallback(
    (tableKey: string, application_id?: string) => {
      if (tableKey) {
        const formattedData = tableData.map((item, index) => ({
          id: item.id,
          sort_order: item.sort_order,
        }));

        dispatch(
          submitConfigurationForm({
            formData: { application_id, sort_order: formattedData },
            tableKey,
            sorting: true,
            hasPagination: true,
          }),
        );
      }
    },
    [dispatch, tableData],
  );

  const handleCheck = useCallback(
    (tableKey: string, id: string) => {
      dispatch(selectTableData({ tableKey, id }));
    },
    [dispatch],
  );

  const handleDisable = useCallback(() => {
    return disabledHandlers;
  }, [disabledHandlers]);

  return {
    [TableActionHandlerType.handleEdit]: activeHandlers.includes(TableActionType.Edit) ? handleEdit : undefined,
    [TableActionHandlerType.handleVerification]: activeHandlers.includes(TableActionType.Verification)
      ? handleVerification
      : undefined,
    [TableActionHandlerType.handleView]: activeHandlers.includes(TableActionType.View) ? handleView : undefined,
    [TableActionHandlerType.handleDelete]: activeHandlers.includes(TableActionType.Delete) ? handleDelete : undefined,
    [TableActionHandlerType.handlePriority]: activeHandlers.includes(TableActionType.Priority)
      ? handlePriority
      : undefined,
    [TableActionHandlerType.handleCreate]: activeHandlers.includes(TableActionType.Create) ? handleCreate : undefined,
    [TableActionHandlerType.handleDisable]: disabledHandlers ? handleDisable : undefined,
    [TableActionHandlerType.handleCheck]: activeHandlers.includes(TableActionType.Check) ? handleCheck : undefined,
  };
}
