import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { updateToken } from '@store';
import { useAppDispatch } from '../storeHooks';

const TOKEN_REFRESH_BUFFER = 60; // Refresh 1 minute before expiry
const IDLE_THRESHOLD = 5 * 60 * 1000; // 5 minutes of inactivity
const CHECK_INTERVAL = 60 * 1000; // Check every minute

interface DecodedToken {
  exp: number;
}

export const useTokenManager = () => {
  const dispatch = useAppDispatch();
  const [isIdle, setIsIdle] = useState(false);
  const checkIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const getToken = useCallback(() => {
    return localStorage.getItem('access_token');
  }, []);

  const checkTokenExpiration = useCallback(() => {
    const token = getToken();
    if (!token) return;

    const decoded = jwt_decode<DecodedToken>(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = decoded.exp - currentTime;

    if (timeUntilExpiry <= TOKEN_REFRESH_BUFFER) {
      dispatch(updateToken({ toast: false }));
    }
  }, [dispatch, getToken]);

  const resetIdleTimer = debounce(() => {
    setIsIdle(false);
  }, 300);

  useEffect(() => {
    const idleTimer = setTimeout(() => setIsIdle(true), IDLE_THRESHOLD);

    const activityEvents = ['mousemove', 'keypress', 'click', 'touchstart'];
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    return () => {
      clearTimeout(idleTimer);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      resetIdleTimer.cancel();
    };
  }, []);

  useEffect(() => {
    if (!isIdle) {
      checkTokenExpiration();
      checkIntervalRef.current = setInterval(checkTokenExpiration, CHECK_INTERVAL);
    } else if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current);
    }

    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [isIdle, checkTokenExpiration]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && !isIdle) {
        checkTokenExpiration();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isIdle, checkTokenExpiration]);

  return null;
};
