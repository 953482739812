import {
  attackCountMockData,
  cacheMockData,
  errorLogMockData,
  qpsMockData,
  topTenURL,
  trafficMockData,
  wafLogsMockResponse,
} from '@/sagas/mockAPI';
import { CdnConfigurationDashboardGraph } from '@enums';

export const noop = <T,>(data: T) => data;

interface Configuration {
  baseName: string;
  basePath: string;
  successMessage: string;
  formatData: (data: any) => any;
  mockResponse: any;
}

export const ConfigurationDashboardConfig = (key: CdnConfigurationDashboardGraph): Configuration => {
  const configurations: Record<string, Configuration> = {
    [CdnConfigurationDashboardGraph.Map]: {
      baseName: 'request_by_country',
      basePath: '/cdn/metric/request_by_country',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: null,
    },
    [CdnConfigurationDashboardGraph.TrafficLog]: {
      baseName: 'dos',
      basePath: '/cdn/metric/access_log',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: null,
    },
    [CdnConfigurationDashboardGraph.QPS]: {
      baseName: 'qps',
      basePath: '/cdn/metric/qps',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: qpsMockData,
    },
    [CdnConfigurationDashboardGraph.RequestPerIp]: {
      baseName: 'request_per_ip',
      basePath: '/cdn/metric/request_per_ip',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: null,
    },
    [CdnConfigurationDashboardGraph.TopTenURL]: {
      baseName: 'top_request_url',
      basePath: '/cdn/metric/top_request_url',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: topTenURL,
    },
    [CdnConfigurationDashboardGraph.AttackLog]: {
      baseName: 'attack-log',
      basePath: '/cdn/application/log/dos',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: wafLogsMockResponse,
    },
    [CdnConfigurationDashboardGraph.ErrorLog]: {
      baseName: 'error-log',
      basePath: '/cdn/log/error_log',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: errorLogMockData,
    },
    [CdnConfigurationDashboardGraph.AttackCount]: {
      baseName: 'attack-count',
      basePath: '/cdn/metric/attack_count',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: attackCountMockData,
    },
    [CdnConfigurationDashboardGraph.Cache]: {
      baseName: 'cache',
      basePath: '/cdn/metric/cache_ratio',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: cacheMockData,
    },
    [CdnConfigurationDashboardGraph.TotalTraffic]: {
      baseName: 'total-traffic',
      basePath: '/cdn/metric/traffic_size',
      successMessage: 'configuration:toasts.dashboard',
      formatData: noop,
      mockResponse: trafficMockData,
    },
  };

  const config = configurations[key];
  return config;
};
