import { Reducer } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import cdnReducer from './cdnSlice';
import configurationReducer from './configuration/configurationSlice';
import initReducer from './initSlice';
import profileReducer from './profileSlice';
import snackReducer from './snackSlice';
import systemReducer from './systemSlice';
import userReducer from './userSlice';
import workspaceReducer from './workspaceSlice';

export const rootReducers: { [key: string]: Reducer } = {
  init: initReducer,
  user: userReducer,
  auth: authReducer,
  workspace: workspaceReducer,
  system: systemReducer,
  snack: snackReducer,
  cdn: cdnReducer,
  configuration: configurationReducer,
  profilesettings: profileReducer,
};
