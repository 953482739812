import i18n from 'i18next';

import { domainValidationReg, ipValidationReg } from '@/regexp';
import * as Yup from 'yup';

export const applicationFormSchema = ({ optionList }) =>
  Yup.object().shape({
    name: Yup.string().required(() => i18n.t('validation:invalidMessage.fieldRequired')),
    domain: Yup.string()
      .required(() => i18n.t('validation:invalidMessage.fieldRequired'))
      .matches(domainValidationReg, () => i18n.t('validation:invalidMessage.invalidDomain'))
      .test('unique-domain', i18n.t('validation:invalidMessage.domainAlreadyExists'), function (value) {
        return value !== 'sample.com';
      }),
    type: Yup.string(),
    origin: Yup.string().matches(ipValidationReg, () => i18n.t('validation:invalidMessage.invalidIPv4Address')),
  });
