import { FieldArray, useField } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch } from '@/hooks';
import {
  resetConfigurationForm,
  useConfigurationError,
  useConfigurationSaveSuccess,
  useConfigurationSubmitting,
} from '@/store';
import { Button, FormField, FormModal, ModalActions } from '@components';
import { CdnConfigurationTab, InputType, VariantType, columnDirection } from '@enums';
import { useTranslation } from 'react-i18next';

interface CacheFileExtensionModalProps {
  name: string;
  open: boolean;
  onSubmit: any;
  formik: any;
  title: string;
  placeholder: string;
  description: string;
  submitError?: any;
  origin: CdnConfigurationTab;
  onClose: () => void;
}

export const CacheFileExtensionModal = ({
  name,
  open,
  title,
  placeholder,
  description,
  formik,
  onSubmit,
  origin,
  onClose,
}: CacheFileExtensionModalProps) => {
  const dispatch = useAppDispatch();

  const tableKey = origin;

  const [field] = useField(name);
  const { t: tButton } = useTranslation('configuration', { keyPrefix: 'buttons.cache' });

  const submitError = useConfigurationError(tableKey);
  const isSubmitting = useConfigurationSubmitting(tableKey);
  const saveSuccess = useConfigurationSaveSuccess(tableKey);

  const passData = useMemo(() => {
    if (Array.isArray(field.value)) {
      return field.value.filter((item) => item.trim() !== '');
    } else if (field.value && typeof field.value === 'string') {
      return field.value
        .split('\n')
        .map((item) => item.trim())
        .filter((item) => item !== '');
    } else {
      return [];
    }
  }, [field.value]);

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      onClose();
      dispatch(resetConfigurationForm(tableKey));
    }
  }, [isSubmitting, saveSuccess, submitError]);

  const handleChange = useCallback((event) => {
    const splitValue = event.target.value.split('\n').map((item) => item.trim());
    field.onChange(splitValue);
  }, []);

  const convertArrayData = useCallback(() => {
    if (Array.isArray(field.value)) {
      return field.value.map((item) => item.replace(/^\n/, '')).join('\n');
    } else field.value ?? '';
  }, [field.value]);

  if (!open && !name) return;

  return (
    <FormModal
      submitError={submitError}
      title={title}
      description={description}
      open={open}
      onClose={onClose}
      className=""
    >
      <FieldArray name={name}>
        {({ form }) => (
          <FormField
            label={title}
            name={name}
            smallFont
            withoutLabel
            centerItem
            textInputClassName="min-h-40"
            direction={columnDirection.Column}
            inputType={InputType.Area}
            value={convertArrayData()}
            onChange={handleChange}
            placeholder={placeholder}
            fullWidth
            className="space-y-3"
          />
        )}
      </FieldArray>
      <ModalActions>
        <Button
          variant={VariantType.Outlined}
          className="rounded-md ring-theme-primary-main ring-opacity-30"
          loading={isSubmitting}
          onClick={onClose}
        >
          <article className="ellipsis-text">{tButton('cancel')}</article>
        </Button>
        <Button
          loading={isSubmitting}
          onClick={(e) => {
            onSubmit(passData, formik, name);
          }}
        >
          {tButton('configure')}
        </Button>
      </ModalActions>
    </FormModal>
  );
};
