import { StatusType, VariantType } from '@/enums';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { colors } from '../Badges/Badge';
import { Button } from '../Buttons';

export enum ChipColors {
  primary = 'primary',
  error = 'error',
}

export enum Direction {
  Left,
  Right,
  Up,
  Down,
  Outside,
  Default,
}

type ChipProps = {
  label: string;
  adornment?: ReactNode;
  direction?: Direction;
  className?: string;
  chipType?: StatusType;
  chipVariant?: VariantType;
  disabled?: boolean;
  disableHover?: boolean;
  smallFont?: boolean;
  onChipClick?: (e?: any) => void;
  rounded?: string;
  color?: keyof typeof colors;
  customizeColor?: boolean;
  withoutPadding?: boolean;
  withoutHover?: boolean;
  withoutScale?: boolean;
  textClassName?: string;
};

export const Chip = ({
  onChipClick,
  label,
  disabled,
  className = '',
  disableHover = false,
  smallFont,
  direction = Direction.Right,
  customizeColor,
  withoutHover,
  withoutScale,
  textClassName,
  chipType = StatusType.Info,
  chipVariant = VariantType.Contained,
  rounded = 'rounded-full',
  color,
  withoutPadding,
  adornment,
}: ChipProps) => {
  const filledChip = chipVariant == VariantType.Contained;
  const OutlinedChip = chipVariant == VariantType.Outlined;
  const InfoChipType = chipType === StatusType.Info;
  const WarningChipType = chipType === StatusType.Warning;
  const DeleteChipType = chipType === StatusType.Delete;
  const ErrorChipType = chipType === StatusType.Error;
  const SuccessChipType = chipType === StatusType.Success;
  const directionRight = direction === Direction.Right;
  const directionLeft = direction === Direction.Left;
  const dynamicClasses = classNames('flex focus:outline-none font-light ', {
    'text-us': smallFont,
    'text-xs': !smallFont,
    'ring-1': OutlinedChip,
    'px-2.5 py-1.5': !withoutPadding,
    'hover:bg-opacity-80 hover:text-opacity-80 hover:ring-opacity-80': !disableHover,
    'bg-theme-primary-main text-white': filledChip && InfoChipType,
    'bg-blue-50 ring-blue-400 text-blue-400': OutlinedChip && InfoChipType,
    'bg-red-50 ring-red-400 text-red-400': OutlinedChip && ErrorChipType,
    'bg-green-50 ring-green-400 text-green-400': OutlinedChip && SuccessChipType,
    'bg-yellow-50 ring-yellow-400 text-yellow-400': OutlinedChip && WarningChipType,
    'ring-gray-300 text-gray-400': OutlinedChip && DeleteChipType,
    'bg-red-500 text-white': filledChip && ErrorChipType,
    [className]: className,
  });

  return (
    <Button
      variant={chipVariant}
      onClick={onChipClick}
      disabled={disabled}
      withoutPadding
      customizeColor
      withoutHover={withoutHover}
      withoutScale={withoutScale}
      rounded={rounded}
      predefinedColor={color}
      className={dynamicClasses}
    >
      <div className="flex space-x-2.5 items-center">
        {directionLeft && adornment}
        <article className={textClassName}>{label}</article>
        {directionRight && adornment}
      </div>
    </Button>
  );
};
