import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import update from 'immutability-helper';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader, RingLoader } from 'react-spinners';

import { DraggableRow, EmptyObject } from '@components';
import { CellType, TableActionHandlerType } from '@enums';
import { useAppDispatch } from '@hooks';
import { getTableSorting, useConfigurationTableData } from '@store';
import { PaginationProps } from '@types';
import Pagination from './Pagination';

export interface TableConfig {
  className?: string;
  width?: number;
  maxWidth?: number | string;
  minWidth?: number | string;
  align?: 'right' | 'left';
  sticky?: boolean;
  format?: (value: any, fullData: any) => any;
  formatLabel?: (value: string, fullData: any) => any;
  cellCallback?: (props: any) => void;
  sortableCallback?: () => void;
  cellType?: CellType;
}

export interface Column extends TableConfig {
  value: string;
  label: string;
  withRightBorder?: boolean;
  disabled?: boolean;
  multiLines?: boolean;
  dotsTextOverflow?: boolean;
  center?: boolean;
}

export interface ActionHandlers<T> {
  [TableActionHandlerType.handleEdit]?: (rowData: T) => void;
  [TableActionHandlerType.handleView]?: (rowData: T) => void;
  [TableActionHandlerType.handleDelete]?: (rowData: T) => void;
  [TableActionHandlerType.handleCreate]?: () => void;
  [TableActionHandlerType.handlePriority]?: (tableKey: string, application_id?: string) => void;
  [TableActionHandlerType.handleRefresh]?: () => void;
  [TableActionHandlerType.handleCheck]?: (tableKey: string, id: string) => void;
  [TableActionHandlerType.handleVerification]?: (rowData?: T) => void;
  [TableActionHandlerType.handleDisable]?: () => void;
}

interface DraggableTableProps<T> {
  rows: T[];
  columns: Column[];
  actionHandlers: ActionHandlers<T>;
  isDraggable?: boolean;
  tableKey?: string;
  isDataLoading?: boolean;
  pagination?: PaginationProps;
  onChangePage?: (page) => void;
}
export const DraggableTable = memo(
  <T extends EmptyObject<T>>({
    rows,
    tableKey = 'Empty',
    actionHandlers,
    columns,
    isDraggable,
    isDataLoading = false,
    pagination,
    onChangePage,
  }: DraggableTableProps<T>) => {
    const dispatch = useAppDispatch();
    const updatedData = useConfigurationTableData(tableKey);

    const { t: tTable } = useTranslation('common', { keyPrefix: 'tables' });

    const [tableData, setTableData] = useState<T[]>(updatedData ?? rows ?? []);
    const [dragEnded, setDragEnded] = useState(false);

    useEffect(() => {
      setTableData(rows);
    }, [rows]);

    useEffect(() => {
      if (dragEnded) {
        dispatch(getTableSorting({ tableKey: tableKey, rowData: tableData }));
        setDragEnded(false);
      }
    }, [dispatch, dragEnded, tableData, tableKey]);

    const handleMoveRow = useCallback((dragIndex: number, hoverIndex: number) => {
      if (dragIndex === hoverIndex) {
        return;
      }
      setTableData((prevTableData) => {
        const updatedRowData = update(prevTableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevTableData[dragIndex]],
          ],
        });
        return updatedRowData;
      });
    }, []);

    const handleDragEnd = useCallback(() => {
      setDragEnded(true);
    }, []);

    if (!rows) return;

    return (
      <div className="relative flex flex-col overflow-hidden max-w-screen w-full ring-1 ring-theme-text-secondary rounded-2xl">
        <div className="flex-grow overflow-x-auto overflow-y-hidden padded-horizontal-scrollbar-track">
          <table className="w-full divide-y divide-[#f3f2f7] rounded-2xl">
            <thead>
              <tr className="bg-gray-50 bg-opacity-90 select-none">
                {columns?.map(({ label, width = 60, minWidth, maxWidth, center = false }, index) => {
                  return (
                    <th
                      key={index}
                      className={`py-4 ${label === '#' || label === 'Order' ? 'px-6' : 'px-2.5'} ${center ? 'text-center' : ''} text-left text-xs font-normal text-gray-400`}
                      style={{ width: width, minWidth: minWidth, maxWidth: maxWidth }}
                    >
                      {label}
                    </th>
                  );
                })}
              </tr>
              {isDataLoading && (
                <tr className="relative h-1">
                  <th className="p-0 absolute w-full top-0 left-0 bg-gray-50" colSpan={columns.length}>
                    <BarLoader className="block" width={'100%'} color="#417788" loading={isDataLoading} />
                  </th>
                </tr>
              )}
            </thead>
            <tbody className="bg-white py-3">
              {tableData?.length > 0 &&
                !isDataLoading &&
                tableData?.map((row: T, rowIndex: number) => (
                  <DraggableRow
                    isDraggable={isDraggable}
                    tableKey={tableKey}
                    actionHandlers={actionHandlers}
                    key={rowIndex}
                    columns={columns}
                    row={row}
                    index={rowIndex}
                    pagination={pagination}
                    handleDragEnd={handleDragEnd}
                    handleMoveRow={handleMoveRow}
                  />
                ))}
              {isDataLoading && (
                <tr className="h-16">
                  <td colSpan={columns.length + 1}>
                    <article className="flex justify-center items-center gap-x-4 text-gray-300">
                      <RingLoader color="#36d7b7" size={15} />
                      {tTable('fetchingData')}
                    </article>
                  </td>
                </tr>
              )}
              {!isDataLoading && tableData?.length === 0 && (
                <tr className="h-16 select-none">
                  <td colSpan={columns.length + 1}>
                    <article className="flex justify-center items-center gap-4 text-gray-300 ">
                      <DocumentMagnifyingGlassIcon className="w-6 h-6 text-gray-300" />
                      {tTable('noRecordFound')}
                    </article>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pagination && pagination?.total !== 0 && onChangePage && (
          <div className={`sticky bottom-0 ${pagination?.total % 2 !== 0 ? 'bg-gray-50' : 'bg-transparent'} pt-8`}>
            <div className="px-6 py-1.5">
              <div className="absolute bottom-0 left-0 right-0 bg-white border-t border-[#f3f2f7]">
                <div className="px-6 py-1.5">
                  <Pagination pagination={pagination} onChangePage={onChangePage} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
