import {
  customCacheListMockResponse,
  originListMockResponse,
  routeListMockResponse,
  sslCertificateListMockResponse,
  sslCertificateStatusMockData,
  wafListMockResponse,
  wafWhiteListMockResponse,
} from '@/sagas/mockAPI';
import { CdnConfigurationTab } from '@enums';
import { checkResponseCertificateStatus, noOperation } from '@utils';

interface Configuration {
  baseName: string;
  basePath: string;
  verifyPath?: string;
  successMessage: string;
  formatData: (data: any) => any;
  sortPath?: string;
  sortPostPath?: string;
  endPath?: string;
  mockResponse?: any;
  clearPath?: string;
}

export const ConfigurationFormConfig = (key: CdnConfigurationTab): Configuration => {
  const configurations: Record<string, Configuration> = {
    [CdnConfigurationTab.Domain]: {
      baseName: 'domain',
      basePath: '/cdn/domain',
      verifyPath: '/cdn/domain-verify',
      successMessage: 'configuration:toasts.domain',
      formatData: noOperation,
    },
    [CdnConfigurationTab.Origin]: {
      baseName: 'origin',
      basePath: '/cdn/origin',
      successMessage: 'configuration:toasts.origin',
      formatData: noOperation,
      mockResponse: originListMockResponse,
    },
    [CdnConfigurationTab.Route]: {
      baseName: 'route',
      basePath: '/cdn/route',
      sortPath: '/cdn/route/list',
      sortPostPath: '/cdn/route/sort',
      successMessage: 'configuration:toasts.route',
      formatData: noOperation,
      mockResponse: routeListMockResponse,
    },
    [CdnConfigurationTab.SSL]: {
      baseName: 'ssl',
      basePath: '/cdn/ssl',
      successMessage: 'configuration:toasts.ssl',
      formatData: checkResponseCertificateStatus,
      mockResponse: sslCertificateListMockResponse,
    },
    [CdnConfigurationTab.SSLLog]: {
      baseName: 'ssl',
      basePath: '/cdn/ssl',
      endPath: '/acme_log',
      successMessage: 'configuration:toasts.ssl',
      formatData: noOperation,
      mockResponse: sslCertificateStatusMockData,
    },
    [CdnConfigurationTab.PredefinedWAF]: {
      baseName: 'predefinedWaf',
      basePath: '/cdn/predefined-waf',
      successMessage: 'configuration:toasts.waf',
      formatData: noOperation,
      mockResponse: wafListMockResponse,
    },
    [CdnConfigurationTab.CustomWAF]: {
      baseName: 'customWaf',
      basePath: '/cdn/custom-waf',
      successMessage: 'configuration:toasts.customWaf',
      formatData: noOperation,
      mockResponse: wafListMockResponse,
    },
    [CdnConfigurationTab.WhitelistWAF]: {
      baseName: 'whiteListWaf',
      basePath: '/cdn/whitelist-waf',
      successMessage: 'configuration:toasts.whitelistWaf',
      formatData: noOperation,
      mockResponse: wafWhiteListMockResponse,
    },
    [CdnConfigurationTab.PredefinedPageRules]: {
      baseName: 'predefinedPageRules',
      basePath: '/cdn/predefined-page-rules',
      successMessage: 'configuration:toasts.predefinedPageRules',
      formatData: noOperation,
    },
    [CdnConfigurationTab.PageRules]: {
      baseName: 'pageRules',
      basePath: '/cdn/page-rules',
      successMessage: 'configuration:toasts.pageRules',
      formatData: noOperation,
    },
    [CdnConfigurationTab.PredefinedCache]: {
      baseName: 'predefinedCache',
      basePath: '/cdn/predefined-cache',
      successMessage: 'configuration:toasts.predefinedCache',
      clearPath: '/cdn/cache/clear-all',
      formatData: noOperation,
    },
    [CdnConfigurationTab.CustomCache]: {
      baseName: 'customCache',
      basePath: '/cdn/custom-cache',
      successMessage: 'configuration:toasts.customCache',
      formatData: noOperation,
      mockResponse: customCacheListMockResponse,
    },
    [CdnConfigurationTab.Configuration]: {
      baseName: 'configuration',
      basePath: '/cdn/deploy',
      successMessage: 'configuration:toasts.configuration',
      formatData: noOperation,
      mockResponse: customCacheListMockResponse,
    },
    [CdnConfigurationTab.Security]: {
      baseName: 'security',
      basePath: '/cdn/predefined-security-rules',
      successMessage: 'configuration:toasts.configuration',
      formatData: noOperation,
      mockResponse: customCacheListMockResponse,
    },
  };

  const config = configurations[key];
  return config;
};
