import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  addSnack,
  clearCdnError,
  createCdnApplicationError,
  createCdnApplicationSuccess,
  getCdnList,
  getCdnListError,
  getCdnListSuccess,
  submitCdnApplicationForm,
} from '@store';

import { workSpaceId } from '@/storage';
import { StatusType } from '@enums';
import { API } from '@utils';

export function* cdnListSaga() {
  const workspaceId = workSpaceId.getItem();
  try {
    const { data } = yield call(API.get, `/cdn/application?workspace_id=${workspaceId}`);
    yield put(getCdnListSuccess(data));
  } catch (error) {
    yield put(getCdnListError(error as Error));
  }
}

export function* submitCdnApplicationFormSaga({ payload }: PayloadAction<any>) {
  const workspaceId = workSpaceId.getItem();
  try {
    const { data } = yield call(API.post, `/cdn/application`, {
      workspace_id: workspaceId,
      ...payload,
    });
    yield put(clearCdnError());
    yield put(createCdnApplicationSuccess(data));
    yield put(addSnack({ type: StatusType.Success, message: 'Successfully added new application' }));
  } catch (error: any) {
    const errorMessge = error?.response?.data?.data ?? error?.response?.data?.message;
    yield put(createCdnApplicationError(errorMessge));
  }
}

export function* cdnSagaWatcher() {
  yield takeLatest(getCdnList.type, cdnListSaga);
  yield takeLatest(submitCdnApplicationForm.type, submitCdnApplicationFormSaga);
}
