export enum CdnConfigurationTab {
  Dashboard = 'dashboard',
  Domain = 'domain',
  Origin = 'origin',
  Route = 'route',
  SSL = 'ssl',
  SSLLog = 'sslLog',
  PredefinedWAF = 'predefinedWaf',
  CustomWAF = 'customWaf',
  WhitelistWAF = 'whitelistWaf',
  PageRules = 'pageRules',
  PredefinedPageRules = 'predefinedPageRules',
  PredefinedCache = 'predefinedCache',
  CustomCache = 'customCache',
  Configuration = 'configuration',
  Security = 'security',
}

export enum CdnConfigurationDashboardGraph {
  Map = 'map',
  TrafficLog = 'trafficLog',
  RequestPerIp = 'requestPerIp',
  TopTenURL = 'topTenURL',
  QPS = 'qps',
  AttackLog = 'attackLog',
  AttackCount = 'attackCount',
  Cache = 'cache',
  TotalTraffic = 'totalTraffic',
  ErrorLog = 'errorLog',
}

export enum CdnDashboardFilter {
  Time = 'interval',
  Domain = 'domain_id',
}
